import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility/utility";

const initialState = {
	accreditationContract: null,
	signedAccreditationContract: null,
	successMessage: null,
	errors: [],
	loading: false,
	investors: [],
	isSpinning: false,
	countries: [],
	successAlert: false,
	banklist: [],
};

const getInverstorProfileStart = (state, action) => {
	return updateObject(state, {
		errors: [],
		loading: true,
		successMessage: null,
	});
};
/**
 * @description : Getting the axios `successMessage` from Saga
 * @param {param} state
 * @param {param} action
 */
const getInverstorProfileSuccess = (state, action) => {
	return updateObject(state, {
		investors: action.response,
		errors: [],
		loading: false,
	});
};

const getInverstorProfileFail = (state, action) => {
	return updateObject(state, {
		errors: action.errors,
		loading: false,
		successMessage: false,
	});
};

const createInverstorProfileStart = (state, action) => {
	return updateObject(state, {
		errors: [],
		loading: true,
		successMessage: null,
		successAlert: false,
	});
};

const createInverstorProfileSuccess = (state, action) => {
	return updateObject(state, {
		successMessage: action,
		errors: [],
		loading: false,
		successAlert: true,
	});
};

const createInverstorProfileFail = (state, action) => {
	return updateObject(state, {
		errors: action.errors,
		loading: false,
		successMessage: false,
		successAlert: false,
	});
};
const getAccreditationContractSuccess = (state, action) => {
	return updateObject(state, {
		accreditationContract: action.response,
		signedAccreditationContract: null,
	});
};

const getSignedAccreditationContractSuccess = (state, action) => {
	return updateObject(state, {
		signedAccreditationContract: action.response,
	});
};

const updateInverstorProfileStart = (state, action) => {
	return updateObject(state, {
		errors: [],
		loading: true,
		successMessage: null,
		successAlert: false,
	});
};

const updateInverstorProfileSuccess = (state, action) => {
	return updateObject(state, {
		updateSuccess: action.investor,
		errors: [],
		loading: false,
		successAlert: true,
	});
};

const updateInverstorProfileFail = (state, action) => {
	return updateObject(state, {
		errors: action.errors,
		loading: false,
		successMessage: false,
		successAlert: false,
	});
};

const isSpinning = (state, action) => {
	return updateObject(state, {
		isSpinning: action.response,
	});
};

const clearSuccessAlert = (state, action) => {
	return updateObject(state, {
		successAlert: false,
	});
};

const getCountries = (state, action) => {
	return updateObject(state, {
		countries: action.response,
	});
};

const getBanks = (state, action) => {
	return updateObject(state, {
		banklist: action.response,
	});
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_INVESTOR_PROFILE_START:
			return getInverstorProfileStart(state, action);
		case actionTypes.GET_INVESTOR_PROFILE_SUCCESS:
			return getInverstorProfileSuccess(state, action);
		case actionTypes.GET_INVESTOR_PROFILE_FAIL:
			return getInverstorProfileFail(state, action);
		case actionTypes.CREATE_INVESTOR_PROFILE_START:
			return createInverstorProfileStart(state, action);
		case actionTypes.CREATE_INVESTOR_PROFILE_SUCCESS:
			return createInverstorProfileSuccess(state, action);
		case actionTypes.CREATE_INVESTOR_PROFILE_FAIL:
			return createInverstorProfileFail(state, action);
		case actionTypes.GET_ACCREDIATION_CONTRACT_SUCCESS:
			return getAccreditationContractSuccess(state, action);
		case actionTypes.GET_SIGNED_ACCREDIATION_CONTRACT_SUCCESS:
			return getSignedAccreditationContractSuccess(state, action);
		case actionTypes.UPDATE_INVESTOR_PROFILE_START:
			return updateInverstorProfileStart(state, action);
		case actionTypes.UPDATE_INVESTOR_PROFILE_SUCCESS:
			return updateInverstorProfileSuccess(state, action);
		case actionTypes.UPDATE_INVESTOR_PROFILE_FAIL:
			return updateInverstorProfileFail(state, action);
		case actionTypes.GET_COUNTRY_SUCCESS:
			return getCountries(state, action);
		case actionTypes.GET_BANKS_SUCCESS:
			return getBanks(state, action);
		case actionTypes.IS_SPINNING:
			return isSpinning(state, action);
		case actionTypes.CLEAR_SUCCESS_ALERT:
			return clearSuccessAlert(state, action);
		default:
			return state;
	}
};

export default reducer;
