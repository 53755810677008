import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility/utility";

const initialState = {
	token: null,
	adminAuth: null,
	error: null,
	successLogin: false,
	alert: false,
	showResendLink: null,
	resendEmailMessage: null,
	enableTwoFactorAuth: null,
	isSpinning: false
};

/**
 * @function authStart
 * @param {string} state - State before reducer.
 * @param {object} action - Action sent to reducer.
 * @returns {string} - Return new state
 */
const authStart = (state, action) => {
	return updateObject(state, {
		error: null,
		successLogin: true,
		alert: false,
		showResendLink: null,
		resendEmailMessage: null,
		isSpinning: false
	});
};

const authClearError = (state, action) => {
	return updateObject(state, {
		error: null,
		successLogin: false,
		alert: false,
		showResendLink: null,
		isSpinning: false
	});
};

const clearTwoFactorLoginError = (state, action) => {
	return updateObject(state, {
		error: null
	});
};

const clearTwoFactorLoginData = (state, action) => {
	return updateObject(state, {
		enableTwoFactorAuth: null
	});
};

const twoFactorAuthSuccess = (state, action) => {
	return updateObject(state, {
		enableTwoFactorAuth: action.twoFactorStatus
	});
};
/**
 * @function authSuccess
 * @param {string} state - State before reducer.
 * @param {object} action - Action sent to reducer.
 * @returns {string} - New state (authSuccess payload from action).
 */
const authSuccess = (state, action) => {
	return updateObject(state, {
		token: action.token,
		error: null,
		showResendLink: null,
		successLogin: false
	});
};

const adminLoginSuccess = (state, action) => {
	return updateObject(state, {
		adminAuth: action.response
	});
};

const clearAdminLogin = (state, action) => {
	return updateObject(state, {
		adminAuth: null
	});
};

/**
 * @function authFail
 * @param {string} state - State before reducer.
 * @param {object} action - Action sent to reducer.
 * @returns {string} - New state (authFail payload from action).
 */
const authFail = (state, action) => {
	return updateObject(state, {
		error: action.error.message,
		showResendLink: action.error.showResendLink,
		alert: true
	});
};

/**
 * @function authLogout
 * @param {string} state - State before reducer.
 * @param {object} action - Action sent to reducer.
 * @returns {string} - New state (authLogout payload from action).
 */
const authLogout = (state, action) => {
	return updateObject(undefined, { token: null });
};

const setAlert = (state, action) => {
	return updateObject(state, {
		alert: action.response
	});
};

const resendEmailVerificationSuccess = (state, action) => {
	return updateObject(state, {
		resendEmailMessage: action.response,
		showResendLink: null
	});
};

const resendEmailVerificationFail = (state, action) => {
	return updateObject(state, {
		error: action.response,
		showResendLink: null,
		alert: false
	});
};

const clearResendEmailVerification = (state, action) => {
	return updateObject(state, {
		resendEmailMessage: null,
		showResendLink: null,
		error: null
	});
};

const isSpinning = (state, action) => {
	return updateObject(state, {
		isSpinning: action.response
	});
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.AUTH_START:
			return authStart(state, action);
		case actionTypes.AUTH_SUCCESS:
			return authSuccess(state, action);
		case actionTypes.ADMIN_LOGIN_SUCCESS:
			return adminLoginSuccess(state, action);
		case actionTypes.CLEAR_ADMIN_LOGIN:
			return clearAdminLogin(state, action);
		case actionTypes.AUTH_FAIL:
			return authFail(state, action);
		case actionTypes.AUTH_LOGOUT:
			return authLogout(state, action);
		case actionTypes.SHOW_ALERT:
			return setAlert(state, action);
		case actionTypes.CLEAR_AUTH_ERROR_STATE:
			return authClearError(state, action);
		case actionTypes.RESEND_EMAIL_VERIFICATION_LINK_SUCCESS:
			return resendEmailVerificationSuccess(state, action);
		case actionTypes.CLEAR_RESEND_EMAIL_VERIFICATION_LINK:
			return clearResendEmailVerification(state, action);
		case actionTypes.RESEND_EMAIL_VERIFICATION_LINK_FAIL:
			return resendEmailVerificationFail(state, action);
		case actionTypes.TWO_FACTOR_AUTH_SUCCESS:
			return twoFactorAuthSuccess(state, action);
		case actionTypes.CLEAR_TWO_FACTOR_LOGIN_ERROR:
			return clearTwoFactorLoginError(state, action);
		case actionTypes.CLEAR_TWO_FACTOR_LOGIN_DATA:
			return clearTwoFactorLoginData(state, action);
		case actionTypes.IS_SPINNING:
			return isSpinning(state, action);
		default:
			return state;
	}
};

export default reducer;
