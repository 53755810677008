import * as actionTypes from "../../actions/revenue/actionTypes";
import { updateObject } from "../../utility/utility";

const initialState = {
	revenue: [],
	error: [],
	isLoading: false,
};

const getRevenueStart = (state, action) => {
	return updateObject(state, {
		error: [],
		isLoading: false,
	});
};

const getRevenueSuccess = (state, action) => {
	return updateObject(state, {
		revenue: action.response,
	});
};

const getRevenueFail = (state, action) => {
	return updateObject(state, {
		error: action.error,
	});
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_REVENUE_START:
			return getRevenueStart(state, action);
		case actionTypes.GET_REVENUE_SUCCESS:
			return getRevenueSuccess(state, action);
		case actionTypes.GET_REVENUE_FAIL:
			return getRevenueFail(state, action);
		default:
			return state;
	}
};
export default reducer;
