import * as actionTypes from "./actionTypes";

export const siteSettingsStart = () => {
	return {
		type: actionTypes.SITE_SETTINGS_START
	};
};

export const siteSettingsFail = error => {
	return {
		type: actionTypes.SITE_SETTINGS_FAIL,
		error
	};
};

export const getSiteSettings = () => {
	return {
		type: actionTypes.GET_SITE_SETTINGS
	};
};

export const getSiteSettingsSuccess = response => {
	return {
		type: actionTypes.GET_SITE_SETTINGS_SUCCESS,
		response
	};
};

export const getCampaignSettingsStart = () => {
	return {
		type: actionTypes.GET_CAMPAIGN_SETTINGS_START
	};
};

export const getCampaignSettings = () => {
	return {
		type: actionTypes.GET_CAMPAIGN_SETTINGS
	};
};

export const getCampaignSettingsSuccess = response => {
	return {
		type: actionTypes.GET_CAMPAIGN_SETTINGS_SUCCESS,
		response
	};
};

export const getCampaignSettingFail = error => {
	return {
		type: actionTypes.GET_CAMPAIGN_SETTINGS_FAIL,
		error
	};
};

export const getCurrency = () => {
	return {
		type: actionTypes.GET_CURRENCY
	};
};

export const getCurrencySuccess = response => {
	return {
		type: actionTypes.GET_CURRENCY_SUCCESS,
		response
	};
};

export const getACHCampaignSetting = () => {
	return {
		type: actionTypes.GET_ACH_CAMPAIGN_SETTING
	};
};

export const getACHCampaignSettingSuccess = response => {
	return {
		type: actionTypes.GET_ACH_CAMPAIGN_SETTING_SUCCESS,
		response
	};
};

export const getSocialSettingStart = () => {
	return {
		type: actionTypes.GET_SOCIAL_SETTING_START
	};
};

export const getSocialSetting = socialEntity => {
	return {
		type: actionTypes.GET_SOCIAL_SETTING,
		socialEntity
	};
};

export const getSocialSettingFail = error => {
	return {
		type: actionTypes.GET_SOCIAL_SETTING_FAIL,
		error
	};
};

export const getSocialGoogleSettingSuccess = response => {
	return {
		type: actionTypes.GET_SOCIAL_GOOGLE_SETTING_SUCCESS,
		response
	};
};

export const getSocialFacebookSettingSuccess = response => {
	return {
		type: actionTypes.GET_SOCIAL_FACEBOOK_SETTING_SUCCESS,
		response
	};
};

export const getSocialLinkedinSettingSuccess = response => {
	return {
		type: actionTypes.GET_SOCIAL_LINKEDIN_SETTING_SUCCESS,
		response
	};
};