import * as actionTypes from "./actionTypes";

/**
 * @description A method with `start` is used to reset the redux state of the current store
 * @returns {void}
 */
export const metaStart = () => {
	return {
		type: actionTypes.META_START,
	};
};

/**
 * @description A method with `fail` in it stores the error response from the server
 * @param {object} error
 * @returns {object}
 */
export const metaFail = (error) => {
	return {
		type: actionTypes.META_FAIL,
		error,
	};
};

/**
 * @description we will call this method to fetch the Meta details from the server
 * @param {string} language
 * @returns `action`
 */
export const getMeta = (language) => {
	return {
		type: actionTypes.GET_META,
		language,
	};
};

/**
 * @description we will use this method to store success response from the server
 * @param {object} response
 * @returns {object} response
 */
export const getMetaSuccess = (response) => {
	return {
		type: actionTypes.GET_META_SUCCESS,
		response,
	};
};
