export const COMPANY_LOGO_IMG = {
    MAX_HEIGHT: 283,
    MAX_WIDTH: 360,
    MIN_HEIGHT: 283,
    MIN_WIDTH: 360
};

export const EXTRA_IMG = {
    MAX_HEIGHT: 500,
    MAX_WIDTH: 700,
    MIN_HEIGHT: 500,
    MIN_WIDTH: 700
};

export const LEAD_INVESTOR_IMG = {
    MAX_HEIGHT: 283,
    MAX_WIDTH: 360,
    MIN_HEIGHT: 283,
    MIN_WIDTH: 360
};

export const SIGNATURE_IMG = {
    HEIGHT: 200,
    WIDTH: 400,
};

export const STORY_IMG = {
    MAX_HEIGHT: 500,
    MAX_WIDTH: 700,
    MIN_HEIGHT: 500,
    MIN_WIDTH: 700
};

export const TEAM_MEMBER_PROFILE_IMG = {
    MAX_HEIGHT: 283,
    MAX_WIDTH: 360,
    MIN_HEIGHT: 283,
    MIN_WIDTH: 360
};

export const USER_PROFILE_IMG = {
    MAX_HEIGHT: 200,
    MAX_WIDTH: 200,
    MIN_HEIGHT: 200,
    MIN_WIDTH: 200
};




