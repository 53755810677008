import i18n from "i18next";
import Backend from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import translationAR from "./languages/ar/translation.json";
import translationEN from "./languages/en/translation.json";
import translationFR from "./languages/fr/translation.json";

const resources = {
	ar: {
		translation: translationAR,
	},
	en: {
		translation: translationEN,
	},
	fr: {
		translation: translationFR,
	},
};

i18n
	.use(Backend)
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		resources,
		fallbackLng: "ar",
		debug: false,

		interpolation: {
			escapeValue: false,
		},
		react: {
			useSuspense: false,
		},
	});

export default i18n;
