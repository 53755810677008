import * as actionTypes from "../../actions/activateAccount/actionTypes";
import { updateObject } from "../../utility/utility";

const initialState = {
	errors: "",
	isSpinning: false,
	account: [],
	twoFactorLoginError: "",
	twoFactorQrCode: null
};

const activateAccountStart = (state, action) => {
	return updateObject(state, {
		errors: "",
		isSpinning: false,
		account: [],
		twoFactorLoginError: ""
	});
};

const activateAccountSuccess = (state, action) => {
	return updateObject(state, {
		account: action.response,
		errors: "",
		twoFactorLoginError: ""
	});
};

const isSpinning = (state, action) => {
	return updateObject(state, {
		isSpinning: action.response
	});
};

const activateAccountFail = (state, action) => {
	return updateObject(state, {
		errors: action.error
	});
};

const clearErrorauthTwoFactorActivateAccount = (state, action) => {
	return updateObject(state, {
		errors: ""
	});
};

const twoFactorLoginFail = (state, action) => {
	return updateObject(state, {
		twoFactorLoginError: action.error
	});
};

const clearTwoFactorLoginFail = (state, action) => {
	return updateObject(state, {
		twoFactorLoginError: ""
	});
};

const getTwoFactorQrCodeSuccess = (state, action) => {
	return updateObject(state, {
		twoFactorQrCode: action.response
	});
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.ACTIVATE_ACCOUNT_START:
			return activateAccountStart(state, action);
		case actionTypes.ACTIVATE_ACCOUNT_FAIL:
			return activateAccountFail(state, action);
		case actionTypes.ACTIVATE_ACCOUNT_SUCCESS:
			return activateAccountSuccess(state, action);
		case actionTypes.CLEAR_ERROR_AUTH_TWO_FACTOR_ACTIVATE_ACCOUNT:
			return clearErrorauthTwoFactorActivateAccount(state, action);
		case actionTypes.IS_SPINNING:
			return isSpinning(state, action);
		case actionTypes.TWO_FACTOR_LOGIN_FAIL:
			return twoFactorLoginFail(state, action);
		case actionTypes.CLEAR_TWO_FACTOR_LOGIN_FAIL:
			return clearTwoFactorLoginFail(state, action);
		case actionTypes.GET_TWO_FACTOR_QR_CODE_SUCCESS:
			return getTwoFactorQrCodeSuccess(state, action);
		default:
			return state;
	}
};

export default reducer;
