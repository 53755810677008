import * as actionTypes from "./actionTypes";

export const createCampaignStart = () => {
	return {
		type: actionTypes.CAMPAIGNS_START,
	};
};

export const isSpinning = (response) => {
	return {
		type: actionTypes.IS_SPINNING,
		response,
	};
};

export const isPaymentSpinning = (response) => {
	return {
		type: actionTypes.IS_PAYMENT_SPINNING,
		response,
	};
};

export const createCampaignFail = (error) => {
	return {
		type: actionTypes.CAMPAIGNS_FAIL,
		error,
	};
};

export const userCampaignLimitFail = (response) => {
	return {
		type: actionTypes.USER_CAMPAIGNS_LIMIT_FAIL,
		response,
	};
};

export const clearUserCampaignsLimitMessage = () => {
	return {
		type: actionTypes.CLEAR_USER_CAMPAIGNS_LIMIT_MESSAGE,
	};
};

export const createCampaignType = (typeId) => {
	return {
		type: actionTypes.CREATE_CAMPAIGN_TYPE,
		typeId,
	};
};

export const createCampaignTypeSuccess = (response) => {
	return {
		type: actionTypes.CREATE_CAMPAIGN_TYPE_SUCCESS,
		response,
	};
};

export const getType = () => {
	return {
		type: actionTypes.GET_TYPE,
	};
};

export const getTypeSuccess = (response) => {
	return {
		type: actionTypes.GET_TYPE_SUCCESS,
		response,
	};
};

export const getCategories = (language) => {
	return {
		type: actionTypes.GET_CATEGORIES,
		language,
	};
};

export const getCategoriesSuccess = (response) => {
	return {
		type: actionTypes.GET_CATEGORIES_SUCCESS,
		response,
	};
};

export const getIndustries = (language) => {
	return {
		type: actionTypes.GET_INDUSTRIES,
		language,
	};
};

export const getIndustriesSuccess = (response) => {
	return {
		type: actionTypes.GET_INDUSTRIES_SUCCESS,
		response,
	};
};

export const getTeamMemberType = (language) => {
	return {
		type: actionTypes.GET_TEAM_MEMBER_TYPE,
		language,
	};
};

export const getTeamMemberTypeSuccess = (response) => {
	return {
		type: actionTypes.GET_TEAM_MEMBER_TYPE_SUCCESS,
		response,
	};
};

/**
 * @param {FormData} teamMemberData `FormData`
 */
export const createTeamMember = (teamMemberData) => {
	return {
		type: actionTypes.CREATE_TEAM_MEMBER,
		teamMemberData,
	};
};

export const createTeamMemberSuccess = (response) => {
	return {
		type: actionTypes.CREATE_TEAM_MEMBER_SUCCESS,
		response,
	};
};

export const getTeamMembers = (campaignId) => {
	return {
		type: actionTypes.GET_TEAM_MEMBERS,
		campaignId,
	};
};

export const getTeamMembersSuccess = (response) => {
	return {
		type: actionTypes.GET_TEAM_MEMBERS_SUCCESS,
		response,
	};
};

export const resendTeamMemberEmail = (id) => {
	return {
		type: actionTypes.RESEND_EMAIL,
		id,
	};
};

export const resendTeamMemberEmailSuccess = (response) => {
	return {
		type: actionTypes.RESEND_EMAIL_SUCCESS,
		response,
	};
};

export const deleteMember = (memberId, campaignId) => {
	return {
		type: actionTypes.DELETE_TEAM_MEMBER,
		memberId,
		campaignId,
	};
};

export const getTeamMember = (memberId, campaignId) => {
	return {
		type: actionTypes.GET_TEAM_MEMBER,
		memberId,
		campaignId,
	};
};

export const getTeamMemberSuccess = (response) => {
	return {
		type: actionTypes.GET_TEAM_MEMBER_SUCCESS,
		response,
	};
};

/**
 * @param {FormdData} teamMemberData
 */
export const updateTeamMember = (teamMemberData) => {
	return {
		type: actionTypes.UPDATE_TEAM_MEMBER,
		teamMemberData,
	};
};

export const clearTeamMember = () => {
	return {
		type: actionTypes.CLEAR_TEAM_MEMBER,
	};
};
/**
 * Basic Company Data : Start
 */

/**
 *
 * @param {String} campaignId
 */
export const getCompanyData = (campaignId) => {
	return {
		type: actionTypes.GET_BASIC_COMPANY_INFORMATION,
		campaignId,
	};
};

/**
 *
 * @param {Object} response
 */
export const getCompanyDataSuccess = (response) => {
	return {
		type: actionTypes.GET_BASIC_COMPANY_INFORMATION_SUCCESS,
		response,
	};
};

/**
 *
 * @param {FormData} companyData
 */
export const createCompany = (companyData) => {
	return {
		type: actionTypes.CREATE_BASIC_COMPANY_INFORMATION,
		companyData,
	};
};

/**
 *
 * @param {FormData} companyData
 */
export const updateCompany = (companyData) => {
	return {
		type: actionTypes.UPDATE_COMPANY_INFORMATION,
		companyData,
	};
};

export const successCommon = (response) => {
	return {
		type: actionTypes.SUCCESS_COMMON,
		response,
	};
};

export const getCampaignStage = (language) => {
	return {
		type: actionTypes.GET_CAMPAIGN_STAGE,
		language,
	};
};

export const getCampaignStageSuccess = (response) => {
	return {
		type: actionTypes.GET_CAMPAIGN_STAGE_SUCCESS,
		response,
	};
};

export const getInvestingRound = (language) => {
	return {
		type: actionTypes.GET_INVESTING_ROUND,
		language,
	};
};

export const getInvestingRoundSuccess = (response) => {
	return {
		type: actionTypes.GET_INVESTING_ROUND_SUCCESS,
		response,
	};
};

export const getInvestor = (language) => {
	return {
		type: actionTypes.GET_INVESTOR,
		language,
	};
};

export const getInvestorSuccess = (response) => {
	return {
		type: actionTypes.GET_INVESTOR_SUCCESS,
		response,
	};
};

export const createFundRaising = (fund) => {
	return {
		type: actionTypes.CREATE_FUNDRAISING,
		fund,
	};
};

export const updateFundRaising = (fund) => {
	return {
		type: actionTypes.UPDATE_FUNDRAISING,
		fund,
	};
};

export const getDealTypes = (language) => {
	return {
		type: actionTypes.GET_DEAL_TYPES,
		language,
	};
};

export const getDealTypesSuccess = (response) => {
	return {
		type: actionTypes.GET_DEAL_TYPES_SUCCESS,
		response,
	};
};

export const getDealType = (language) => {
	return {
		type: actionTypes.GET_DEAL_TYPE,
		language,
	};
};

export const getDealTypeSuccess = (response) => {
	return {
		type: actionTypes.GET_DEAL_TYPE_SUCCESS,
		response,
	};
};

export const getCampaigns = (language) => {
	return {
		type: actionTypes.GET_CAMPAIGNS,
		language,
	};
};

export const getCampaignsSuccess = (response) => {
	return {
		type: actionTypes.GET_CAMPAIGNS_SUCCESS,
		response,
	};
};

export const deleteCampaign = (id) => {
	return {
		type: actionTypes.DELETE_CAMPAIGN,
		id,
	};
};

export const getFundraising = (campaignId) => {
	return {
		type: actionTypes.GET_FUNDRAISING,
		campaignId,
	};
};

export const getFundraisingSuccess = (response) => {
	return {
		type: actionTypes.GET_FUNDRAISING_SUCCESS,
		response,
	};
};

export const createTerm = (term) => {
	return {
		type: actionTypes.CREATE_TERM,
		term,
	};
};

export const updateTerm = (term) => {
	return {
		type: actionTypes.UPDATE_TERM,
		term,
	};
};

export const getTerm = (campaignId) => {
	return {
		type: actionTypes.GET_TERM,
		campaignId,
	};
};

export const getTermSuccess = (response) => {
	return {
		type: actionTypes.GET_TERM_SUCCESS,
		response,
	};
};

export const getStory = (campaignId) => {
	return {
		type: actionTypes.GET_STORY,
		campaignId,
	};
};

export const getStorySuccess = (response) => {
	return {
		type: actionTypes.GET_STORY_SUCCESS,
		response,
	};
};

export const createStory = (story) => {
	return {
		type: actionTypes.CREATE_STORY,
		story,
	};
};

export const updateStory = (story) => {
	return {
		type: actionTypes.UPDATE_STORY,
		story,
	};
};

export const createExtras = (extras) => {
	return {
		type: actionTypes.CREATE_EXTRAS,
		extras,
	};
};

export const updateExtras = (extras) => {
	return {
		type: actionTypes.UPDATE_EXTRAS,
		extras,
	};
};

export const getExtras = (campaignId) => {
	return {
		type: actionTypes.GET_EXTRAS,
		campaignId,
	};
};

export const getExtrasSuccess = (response) => {
	return {
		type: actionTypes.GET_EXTRAS_SUCCESS,
		response,
	};
};

export const createExtrasImage = (image) => {
	return {
		type: actionTypes.CREATE_EXTRAS_IMAGE,
		image,
	};
};

export const getExtrasImage = (campaignId, id) => {
	return {
		type: actionTypes.GET_EXTRAS_IMAGE,
		campaignId,
		id,
	};
};

export const getExtrasImageSuccess = (response) => {
	return {
		type: actionTypes.GET_EXTRAS_IMAGE_SUCCESS,
		response,
	};
};

export const getExtrasImages = (campaignId) => {
	return {
		type: actionTypes.GET_EXTRAS_IMAGES,
		campaignId,
	};
};

export const getExtrasImagesSuccess = (response) => {
	return {
		type: actionTypes.GET_EXTRAS_IMAGES_SUCCESS,
		response,
	};
};

export const deleteExtrasImage = (campaignId, id) => {
	return {
		type: actionTypes.DELETE_EXTRAS_IMAGE,
		campaignId,
		id,
	};
};

export const updateExtrasImage = (image) => {
	return {
		type: actionTypes.UPDATE_EXTRAS_IMAGE,
		image,
	};
};

export const clearExtrasImage = () => {
	return {
		type: actionTypes.CLEAR_EXTRAS_IMAGE,
	};
};

export const getExtrasDocuments = (campaignId) => {
	return {
		type: actionTypes.GET_EXTRAS_DOCUMENTS,
		campaignId,
	};
};

export const getExtrasDocumentsSuccess = (response) => {
	return {
		type: actionTypes.GET_EXTRAS_DOCUMENTS_SUCCESS,
		response,
	};
};

export const createExtrasDocument = (document) => {
	return {
		type: actionTypes.CREATE_EXTRAS_DOCUMENT,
		document,
	};
};

export const deleteExtrasDocument = (campaignId, id) => {
	return {
		type: actionTypes.DELETE_EXTRAS_DOCUMENT,
		campaignId,
		id,
	};
};

export const getExtrasDocument = (campaignId, id) => {
	return {
		type: actionTypes.GET_EXTRAS_DOCUMENT,
		campaignId,
		id,
	};
};

export const getExtrasDocumentSuccess = (response) => {
	return {
		type: actionTypes.GET_EXTRAS_DOCUMENT_SUCCESS,
		response,
	};
};

export const updateExtrasDocument = (document) => {
	return {
		type: actionTypes.UPDATE_EXTRAS_DOCUMENT,
		document,
	};
};

export const clearExtrasDocument = () => {
	return {
		type: actionTypes.CLEAR_EXTRAS_DOCUMEN,
	};
};

/**
 *
 * @param {string} campaignId
 * @returns
 */
export const getFunding = (campaignId) => {
	return {
		type: actionTypes.GET_FUNDING,
		campaignId,
	};
};

/**
 *
 * @param {object} response
 * @returns
 */
export const getFundingSuccess = (response) => {
	return {
		type: actionTypes.GET_FUNDING_SUCCESS,
		response,
	};
};

export const createFunding = (funding) => {
	return {
		type: actionTypes.CREATE_FUNDING,
		funding,
	};
};

export const updateFunding = (funding) => {
	return {
		type: actionTypes.UPDATE_FUNDING,
		funding,
	};
};

export const createEquity = (campaignId, equity) => {
	return {
		type: actionTypes.CREATE_EQUITY,
		campaignId,
		equity,
	};
};

export const createEquitySuccess = (response) => {
	return {
		type: actionTypes.CREATE_EQUITY_SUCCESS,
		response,
	};
};

export const clearEquity = () => {
	return {
		type: actionTypes.CLEAR_EQUITY,
	};
};

export const getEquity = (id) => {
	return {
		type: actionTypes.GET_EQUTY,
		id,
	};
};

export const getEquitySuccess = (response) => {
	return {
		type: actionTypes.GET_EQUITY_SUCCESS,
		response,
	};
};

export const getTakeAwayEstimateSuccess = (response) => {
	return {
		type: actionTypes.GET_TAKEAWAY_ESTIMATE_SUCCESS,
		response,
	};
};

export const getPublicEquities = (
	pageLimit,
	approvalType = "",
	status = ""
) => {
	return {
		type: actionTypes.GET_PUBLIC_EQUITIES,
		pageLimit,
		approvalType,
		status,
	};
};

export const getPublicEquitiesSuccess = (response) => {
	return {
		type: actionTypes.GET_PUBLIC_EQUITIES_SUCCESS,
		response,
	};
};

export const getUpcomingEquities = (pageLimit, sortBy) => {
	return {
		type: actionTypes.GET_UPCOMING_EQUITIES,
		pageLimit,
		sortBy,
	};
};

export const getUpcomingEquitiesSuccess = (response) => {
	return {
		type: actionTypes.GET_UPCOMING_EQUITIES_SUCCESS,
		response,
	};
};

export const setSpinner = (fieldName, value) => {
	return {
		type: actionTypes.SET_SPINNER,
		fieldName,
		value,
	};
};

export const getPublicSuccessEquities = (pageLimit) => {
	return {
		type: actionTypes.GET_PUBLIC_SUCCESS_EQUITIES,
		pageLimit,
	};
};

export const getPublicSuccessEquitiesSuccess = (response) => {
	return {
		type: actionTypes.GET_PUBLIC_SUCCESS_EQUITIES_SUCCESS,
		response,
	};
};

export const getCampaignCompleted = (status) => {
	return {
		type: actionTypes.GET_CAMPAIGN_COMPLETED,
		status,
	};
};

export const getCampaignCompletedSuccess = (response) => {
	return {
		type: actionTypes.GET_CAMPAIGN_COMPLETED_SUCCESS,
		response,
	};
};

export const searchPublicEquities = (params) => {
	return {
		type: actionTypes.SEARCH_CAMPAIGNS,
		params,
	};
};

export const searchPublicEquitiesSuccess = (response) => {
	return {
		type: actionTypes.SEARCH_CAMPAIGNS_SUCCESS,
		response,
	};
};

export const searchPublicSuccessEquities = (params) => {
	return {
		type: actionTypes.SEARCH_SUCCESS_CAMPAIGNS,
		params,
	};
};

export const getEquities = (exceptFor, pageLimit) => {
	return {
		type: actionTypes.GET_EQUITIES,
		exceptFor,
		pageLimit,
	};
};

export const getEquitiesStart = () => {
	return {
		type: actionTypes.GET_EQUITIES_START,
	};
};

export const getEquitiesSuccess = (response) => {
	return {
		type: actionTypes.GET_EQUITIES_SUCCESS,
		response,
	};
};

export const getFaqs = () => {
	return {
		type: actionTypes.GET_FAQS,
	};
};

export const getFaqsSuccess = (response) => {
	return {
		type: actionTypes.GET_FAQS_SUCCESS,
		response,
	};
};

export const getCustomFaqsSuccess = (response) => {
	return {
		type: actionTypes.GET_CUSTOM_FAQS_SUCCESS,
		response,
	};
};

export const createFaqs = (campaignId, faqs) => {
	return {
		type: actionTypes.CREATE_FAQs,
		campaignId,
		faqs,
	};
};

export const getAnswer = (campaignId) => {
	return {
		type: actionTypes.GET_ANSWER,
		campaignId,
	};
};

export const getAnswerSuccess = (response) => {
	return {
		type: actionTypes.GET_ANSWER_SUCCESS,
		response,
	};
};

export const getEquityUpdates = (campaignId) => {
	return {
		type: actionTypes.GET_EQUITY_UPDATES,
		campaignId,
	};
};

export const resetEquityUpdate = () => {
	return {
		type: actionTypes.RESET_EQUITY_UPDATE,
	};
};

export const getEquityUpdatesSuccess = (response) => {
	return {
		type: actionTypes.GET_EQUITY_UPDATES_SUCCESS,
		response,
	};
};

export const createEquityUpdate = (campaignId, description) => {
	return {
		type: actionTypes.CREATE_EQUITY_UPDATE,
		campaignId,
		description,
		createdAt: Date.now(),
	};
};
/**
 *
 * @param {String} id
 * @param {String} campaignId
 * @param {String} description
 * @returns {void}
 */
export const patchEquityUpdate = (id, campaignId, description) => {
	return {
		type: actionTypes.PATCH_EQUITY_UPDATE,
		id,
		campaignId,
		description,
		createdAt: Date.now(),
	};
};

export const deleteEquityUpdate = (id, campaignId) => {
	return {
		type: actionTypes.DELETE_EQUITY_UPDATE,
		id,
		campaignId,
	};
};

export const getEquityUpdate = (id, campaignId) => {
	return {
		type: actionTypes.GET_EQUITY_UPDATE,
		id,
		campaignId,
	};
};

export const getEquityUpdateSuccess = (response) => {
	return {
		type: actionTypes.GET_EQUITY_UPDATE_SUCCESS,
		response,
	};
};

export const getCampaignPage = (slug) => {
	return {
		type: actionTypes.GET_CAMPAIGN_PAGE,
		slug,
	};
};

export const getCampaignPageSuccess = (response) => {
	return {
		type: actionTypes.GET_CAMPAIGN_PAGE_SUCCESS,
		response,
	};
};

export const getPublicUpdates = (id) => {
	return {
		type: actionTypes.GET_PUBLIC_UPDATES,
		id,
	};
};
export const getPublicUpdatesSuccess = (response) => {
	return {
		type: actionTypes.GET_PUBLIC_UPDATES_SUCCESS,
		response,
	};
};

export const getPublicDocuments = (id) => {
	return {
		type: actionTypes.GET_PUBLIC_DOCUMENTS,
		id,
	};
};

export const getPublicDocumentsSuccess = (response) => {
	return {
		type: actionTypes.GET_PUBLIC_DOCUMENTS_SUCCESS,
		response,
	};
};

export const getPublicFaqs = (id) => {
	return {
		type: actionTypes.GET_PUBLIC_FAQS,
		id,
	};
};

export const getPublicFaqsSuccess = (response) => {
	return {
		type: actionTypes.GET_PUBLIC_FAQS_SUCCESS,
		response,
	};
};

export const saveCampaign = (id) => {
	return {
		type: actionTypes.SAVE_CAMPAIGN,
		id,
	};
};

export const unSaveCampaign = (id) => {
	return {
		type: actionTypes.UNSAVE_CAMPAIGN,
		id,
	};
};

export const saveCampaignSuccess = (response) => {
	return {
		type: actionTypes.SAVE_CAMPAIGN_SUCCESS,
		response,
	};
};

export const getSavedCampaigns = () => {
	return {
		type: actionTypes.GET_SAVE_CAMPAIGN,
	};
};

export const getSavedCampaignsSuccess = (response) => {
	return {
		type: actionTypes.GET_SAVE_CAMPAIGN_SUCCESS,
		response,
	};
};

export const getSavedCampaign = (id) => {
	return {
		type: actionTypes.GET_SAVED_CAMPAIGN,
		id,
	};
};

export const getSavedCampaignSuccess = (response) => {
	return {
		type: actionTypes.GET_SAVED_CAMPAIGN_SUCCESS,
		response,
	};
};

export const createInvestment = (investmentData) => {
	return {
		type: actionTypes.CREATE_INVESTMENT,
		investmentData,
	};
};

export const updateInvestment = (investmentData, paymentType) => {
	return {
		type: actionTypes.UPDATE_INVESTMENT,
		investmentData,
		paymentType,
	};
};

export const createInvestmentSuccess = (response) => {
	return {
		type: actionTypes.CREATE_INVESTMENT_SUCCESS,
		response,
	};
};

export const updateInvestmentSuccess = (response) => {
	return {
		type: actionTypes.UPDATE_INVESTMENT_SUCCESS,
		response,
	};
};

export const getInvestmentContract = () => {
	return {
		type: actionTypes.GET_INVESTMENT_CONTRACT,
	};
};

export const getInvestmentContractSuccess = (response) => {
	return {
		type: actionTypes.GET_INVESTMENT_CONTRACT_SUCCESS,
		response,
	};
};

export const getSignedContract = (data) => {
	return {
		type: actionTypes.GET_SIGNED_CONTRACT,
		data,
	};
};

export const getSignedContractSuccess = (response) => {
	return {
		type: actionTypes.GET_SIGNED_CONTRACT_SUCCESS,
		response,
	};
};

export const getOfflineBank = (typ) => {
	return {
		type: actionTypes.GET_OFFLINE,
		typ,
	};
};

export const getOfflineBankSuccess = (response) => {
	return {
		type: actionTypes.GET_OFFLINE_SUCCESS,
		response,
	};
};

export const getOmanPaymobSetting = (typ) => {
	return {
		type: actionTypes.GET_OMAN_PAYMOB_SETTING,
		typ,
	};
};

export const getOmanPaymobSettingSuccess = (response) => {
	return {
		type: actionTypes.GET_OMAN_PAYMOB_SETTING_SUCCESS,
		response,
	};
};

export const getStripe = (typ) => {
	return {
		type: actionTypes.GET_STRIPE,
		typ,
	};
};

export const getStripeSuccess = (response) => {
	return {
		type: actionTypes.GET_STRIPE_SUCCESS,
		response,
	};
};

export const getPayPalSetting = (typ) => {
	return {
		type: actionTypes.GET_PAYPAL_SETTING,
		typ,
	};
};

export const getPayPalSettingSuccess = (response) => {
	return {
		type: actionTypes.GET_PAYPAL_SETTING_SUCCESS,
		response,
	};
};

export const createOfflinePayment = (offlineData) => {
	return {
		type: actionTypes.CREATE_OFFLINE_PAYMENT,
		offlineData,
	};
};

export const createOfflinePaymentSuccess = (response) => {
	return {
		type: actionTypes.CREATE_OFFLINE_PAYMENT_SUCCESS,
		response,
	};
};

export const walletInvestmentPayment = (walletData) => {
	return {
		type: actionTypes.WALLET_INVESTMENT_PAYMENT,
		walletData,
	};
};

export const walletInvestmentPaymentSuccess = (response) => {
	return {
		type: actionTypes.WALLET_INVESTMENT_PAYMENT_SUCCESS,
		response,
	};
};

export const getCurrentInvest = (id) => {
	return {
		type: actionTypes.GET_CURRENT_INVEST,
		id,
	};
};

export const getCurrentInvestSuccess = (response) => {
	return {
		type: actionTypes.GET_CURRENT_INVEST_SUCCESS,
		response,
	};
};

export const getCurrentInvestByCampaignid = (id, campaignId) => {
	return {
		type: actionTypes.GET_CURRENT_INVEST_BY_CAMPAIGNID,
		id,
		campaignId,
	};
};

export const getCurrentInvestByCampaignidSuccess = (response) => {
	return {
		type: actionTypes.GET_CURRENT_INVEST_BY_CAMPAIGNID_SUCCESS,
		response,
	};
};

export const clearCurrentInvestByCampaignid = () => {
	return {
		type: actionTypes.CLEAR_CURRENT_INVEST_BY_CAMPAIGNID,
	};
};

export const getReceipt = (id) => {
	return {
		type: actionTypes.GET_RECIEPT,
		id,
	};
};

export const getReceiptSuccess = (response) => {
	return {
		type: actionTypes.GET_RECIEPT_SUCCESS,
		response,
	};
};

export const stripePaymentInitiate = (stripeData) => {
	return {
		type: actionTypes.STRIPE_PAYMENT,
		stripeData,
	};
};

export const stripePaymentSuccess = (response) => {
	return {
		type: actionTypes.STRIPE_PAYMENT_SUCCESS,
		response,
	};
};

export const stripePaymentFail = (response) => {
	return {
		type: actionTypes.STRIPE_PAYMENT_FAIL,
		response,
	};
};

export const getStripeResponse = (sessionId) => {
	return {
		type: actionTypes.GET_STRIPE_RESPONSE,
		sessionId,
	};
};

export const getStripeResponseSuccess = (response) => {
	return {
		type: actionTypes.GET_STRIPE_RESPONSE_SUCCESS,
		response,
	};
};

export const setStripeUpdate = (stripeData) => {
	return {
		type: actionTypes.STRIPE_UPDATE,
		stripeData,
	};
};

export const setStripeUpdateSuccess = (response) => {
	return {
		type: actionTypes.STRIPE_UPDATE_SUCCESS,
		response,
	};
};

export const getReservations = (id) => {
	return {
		type: actionTypes.GET_TOTAL_RESERVARTIONS,
		id,
	};
};

export const getReservationsSuccess = (response) => {
	return {
		type: actionTypes.GET_TOTAL_RESERVARTIONS_SUCCESS,
		response,
	};
};

export const getInvestments = (campaignId) => {
	return {
		type: actionTypes.GET_INVESTMENTS,
		campaignId,
	};
};

export const getInvestmentsSuccess = (response) => {
	return {
		type: actionTypes.GET_INVESTMENTS_SUCCESS,
		response,
	};
};

export const getRepayment = (campaignId) => {
	return {
		type: actionTypes.GET_REPAYMENT,
		campaignId,
	};
};

export const getRepaymentSuccess = (response) => {
	return {
		type: actionTypes.GET_REPAYMENT_SUCCESS,
		response,
	};
};

export const getProfitAdjustedRepaymentAmount = (repaymentData) => {
	return {
		type: actionTypes.GET_PROFIT_ADJUSTED_REPAYMENT_AMOUNT,
		repaymentData,
	};
};

export const getProfitAdjustedRepaymentAmountSuccess = (response) => {
	return {
		type: actionTypes.GET_PROFIT_ADJUSTED_REPAYMENT_AMOUNT_SUCCESS,
		response,
	};
};

export const createRepayment = (repaymentData) => {
	return {
		type: actionTypes.CREATE_REPAYMENT,
		repaymentData,
	};
};

export const updateRepayment = (repaymentData) => {
	return {
		type: actionTypes.UPDATE_REPAYMENT,
		repaymentData,
	};
};

export const updateStripeRepayment = (repaymentData) => {
	return {
		type: actionTypes.UPDATE_REPAYMENT_STRIPE,
		repaymentData,
	};
};

export const getSingleRepayment = (repaymentId) => {
	return {
		type: actionTypes.GET_SINGLE_REPAYMENT,
		repaymentId,
	};
};

export const getSingleRepaymentSuccess = (response) => {
	return {
		type: actionTypes.GET_SINGLE_REPAYMENT_SUCCESS,
		response,
	};
};

export const repaymentStripe = (repaymentData) => {
	return {
		type: actionTypes.REPAYMENT_STRIPE,
		repaymentData,
	};
};

export const repaymentStripeSuccess = (response) => {
	return {
		type: actionTypes.REPAYMENT_STRIPE_SUCCESS,
		response,
	};
};

export const requestPayout = (id) => {
	return {
		type: actionTypes.REQUEST_PAYOUT,
		id,
		requestPayoutDate: Date.now(),
		createdAt: Date.now(),
	};
};

export const requestPayoutSuccess = (response) => {
	return {
		type: actionTypes.REQUEST_PAYOUT_SUCCESS,
		response,
	};
};

export const getLeadInvestor = (id, campaignId) => {
	return {
		type: actionTypes.GET_LEAD_INVESTOR,
		id,
		campaignId,
	};
};

export const getLeadInvestorSuccess = (response) => {
	return {
		type: actionTypes.GET_LEAD_INVESTOR_SUCCESS,
		response,
	};
};

export const getLeadInvestors = (campaignId) => {
	return {
		type: actionTypes.GET_LEAD_INVESTORS,
		campaignId,
	};
};

export const getLeadInvestorsSuccess = (response) => {
	return {
		type: actionTypes.GET_LEAD_INVESTORS_SUCCESS,
		response,
	};
};

export const createLeadInvestor = (leadInvestorData) => {
	return {
		type: actionTypes.CREATE_LEAD_INVESTOR,
		leadInvestorData,
	};
};

export const updateLeadInvestor = (leadInvestorData) => {
	return {
		type: actionTypes.UPDATE_LEAD_INVESTOR,
		leadInvestorData,
	};
};

export const deleteLeadInvestor = (id, campaignId) => {
	return {
		type: actionTypes.DELETE_LEAD_INVESTOR,
		id,
		campaignId,
	};
};

export const clearLeadInvestor = () => {
	return {
		type: actionTypes.CLEAR_LEAD_INVESTOR,
	};
};

export const createExtrasVideo = (video) => {
	return {
		type: actionTypes.CREATE_EXTRAS_VIDEO,
		video,
	};
};

export const getExtrasVideo = (campaignId, id) => {
	return {
		type: actionTypes.GET_EXTRAS_VIDEO,
		campaignId,
		id,
	};
};

export const getExtrasVideoSuccess = (response) => {
	return {
		type: actionTypes.GET_EXTRAS_VIDEO_SUCCESS,
		response,
	};
};

export const getExtrasVideos = (campaignId) => {
	return {
		type: actionTypes.GET_EXTRAS_VIDEOS,
		campaignId,
	};
};

export const getExtrasVideosSuccess = (response) => {
	return {
		type: actionTypes.GET_EXTRAS_VIDEOS_SUCCESS,
		response,
	};
};

export const deleteExtrasVideo = (campaignId, id) => {
	return {
		type: actionTypes.DELETE_EXTRAS_VIDEO,
		campaignId,
		id,
	};
};

export const clearExtrasVideo = () => {
	return {
		type: actionTypes.CLEAR_EXTRAS_VIDEO,
	};
};

export const updateExtrasVideo = (video) => {
	return {
		type: actionTypes.UPDATE_EXTRAS_VIDEO,
		video,
	};
};

export const uploadContractCopy = (contractData) => {
	return {
		type: actionTypes.UPLOAD_CONTRACT_COPY,
		contractData,
	};
};

export const uploadContractCopySuccess = (response) => {
	return {
		type: actionTypes.UPLOAD_CONTRACT_COPY_SUCCESS,
		response,
	};
};

export const uploadContractCopyFail = (error) => {
	return {
		type: actionTypes.UPLOAD_CONTRACT_COPY_FAIL,
		error,
	};
};

export const isLoading = (response) => {
	return {
		type: actionTypes.IS_LOADING,
		response,
	};
};

/**
 * * A redux action to get the users who have saved the campaign
 * * We dispatch this action for axios call
 * @param {String} campaignId
 * @returns Object
 */
export const getUsersWhoSavedCampaign = (campaignId) => {
	return {
		type: actionTypes.GET_FOLLOWERS_CAMPAIGN,
		campaignId,
	};
};

export const getUsersWhoSavedCampaignSuccess = (response) => {
	return {
		type: actionTypes.GET_FOLLOWERS_CAMPAIGN_SUCCESS,
		response,
	};
};

export const getUserSaveCampaignIdSuccess = (response) => {
	return {
		type: actionTypes.GET_USER_SAVE_CAMPAIGNID_SUCCESS,
		response,
	};
};

export const clearStripeResponse = () => {
	return {
		type: actionTypes.CLEAR_STRIPE_RESPONSE,
	};
};

export const clearEquityGoogleAnalyticCode = () => {
	return {
		type: actionTypes.CLEAR_EQUITY_GOOGLE_ANALYTIC_CODE,
	};
};
export const equityGoogleAnalyticCode = (response) => {
	return {
		type: actionTypes.EQUITY_GOOGLE_ANALYTIC_CODE,
		response,
	};
};
/// User Profile - Campaign and Investors List

export const getUserProfileCampaignList = (userId, pageLimit) => {
	return {
		type: actionTypes.GET_USER_PROFILE_CAMPAIGNS_LIST,
		userId,
		pageLimit,
	};
};

export const getUserProfileInvestorsList = (userId, pageLimit) => {
	return {
		type: actionTypes.GET_USER_PROFILE_INVESTORS_LIST,
		userId,
		pageLimit,
	};
};

export const getUserProfileInvestorSuccess = (response) => {
	return {
		type: actionTypes.GET_USER_PROFILE_INVESTORS_SUCCESS,
		response,
	};
};

export const getUserProfileCampaignsSuccess = (response) => {
	return {
		type: actionTypes.GET_USER_PROFILE_CAMPAIGNS_SUCCESS,
		response,
	};
};

export const getShuftiProKYC = (typ) => {
	return {
		type: actionTypes.GET_SHUFTI_PRO_KYC,
		typ,
	};
};

export const getShuftiProKYCSuccess = (response) => {
	return {
		type: actionTypes.GET_SHUFTI_PRO_KYC_SUCCESS,
		response,
	};
};

export const getPayPalGenerateToken = () => {
	return {
		type: actionTypes.GET_PAYPAL_GENERATE_TOKEN,
	};
};

export const getPayPalGenerateTokenSuccess = (response) => {
	return {
		type: actionTypes.GET_PAYPAL_GENERATE_TOKEN_SUCCESS,
		response,
	};
};

export const getPayPalConfirmPayment = (response) => {
	return {
		type: actionTypes.GET_PAYPAL_CONFIRM_PAYMENT,
		response,
	};
};

export const getPayPalConfirmPaymentSuccess = (response) => {
	return {
		type: actionTypes.GET_PAYPAL_CONFIRM_PAYMENT_SUCCESS,
		response,
	};
};

export const clearPaypalConfirmPaymentResponse = (response) => {
	return {
		type: actionTypes.CLEAR_PAYPAL_CONFIRM_PAYMENT_RESPONSE,
		response,
	};
};

export const clearStripeConnectionUrl = () => {
	return {
		type: actionTypes.CLEAR_STRIPE_ACH_CONNECTION_URL,
	};
};

export const getUserStripeConnection = () => {
	return {
		type: actionTypes.GET_STRIPE_ACH_CONNECTION_URL,
	};
};

export const getUserStripeConnectionSuccess = (response) => {
	return {
		type: actionTypes.GET_STRIPE_ACH_CONNECTION_URL_SUCCESS,
		response,
	};
};

export const postUserStripeConnected = (data) => {
	return {
		type: actionTypes.POST_STRIPE_ACH_CONNECT,
		data,
	};
};

export const getStripeACHConnectedSuccess = (response) => {
	return {
		type: actionTypes.POST_STRIPE_ACH_CONNECT_SUCCESS,
		response,
	};
};

export const getStripeACHAllUserAccounts = (pageLimit) => {
	return {
		type: actionTypes.GET_STRIPE_ACH_ALL_USER_ACCOUNTS,
		pageLimit,
	};
};

export const getStripeACHAllUserAccountsSuccess = (response) => {
	return {
		type: actionTypes.GET_STRIPE_ACH_ALL_USER_ACCOUNTS_SUCCESS,
		response,
	};
};

export const clearStripeACHConnectedMessage = () => {
	return {
		type: actionTypes.CLEAR_STRIPE_ACH_CONNECT_SUCCESS_MSG,
	};
};

export const disconnectStripeACHAccount = (stripeId, campaignId) => {
	return {
		type: actionTypes.STRIPE_ACH_DISCONNECT_ACCOUNT,
		stripeId,
		campaignId,
	};
};

export const clearStripeACHPaymentToken = () => {
	return {
		type: actionTypes.CLEAR_STRIPE_ACH_PAYMENT_TOKEN,
	};
};

export const getStripeACHPaymentToken = () => {
	return {
		type: actionTypes.GET_STRIPE_ACH_PAYMENT_TOKEN,
	};
};

export const getStripeACHPaymentTokenSuccess = (response) => {
	return {
		type: actionTypes.GET_STRIPE_ACH_PAYMENT_TOKEN_SUCCESS,
		response,
	};
};

export const clearOmanPaymobToken = () => {
	return {
		type: actionTypes.CLEAR_OMAN_PAYMOB_TOKEN,
	};
};

export const getOmanPaymobToken = (walletAmount) => {
	return {
		type: actionTypes.GET_OMAN_PAYMOB_TOKEN,
		walletAmount,
	};
};

export const getOmanPaymobTokenSuccess = (response) => {
	return {
		type: actionTypes.GET_OMAN_PAYMOB_TOKEN_SUCCESS,
		response,
	};
};

export const getACHSetting = (typ) => {
	return {
		type: actionTypes.GET_ACH_SETTING,
		typ,
	};
};

export const getACHSettingSuccess = (response) => {
	return {
		type: actionTypes.GET_ACH_SETTING_SUCCESS,
		response,
	};
};

export const clearStripeACHPaymentConfimation = () => {
	return {
		type: actionTypes.CLEAR_STRIPE_ACH_PAYMENT_CONFIMATION,
	};
};

export const postStripeACHPaymentConfimation = (data) => {
	return {
		type: actionTypes.POST_STRIPE_ACH_PAYMENT_CONFIMATION,
		data,
	};
};

export const postStripeACHPaymentConfimationSuccess = (response) => {
	return {
		type: actionTypes.POST_STRIPE_ACH_PAYMENT_CONFIMATION_SUCCESS,
		response,
	};
};

export const clearStripeACHRePaymentConfimation = () => {
	return {
		type: actionTypes.CLEAR_STRIPE_ACH_RE_PAYMENT_CONFIMATION,
	};
};

export const postStripeACHRePaymentConfimation = (data) => {
	return {
		type: actionTypes.POST_STRIPE_ACH_RE_PAYMENT_CONFIMATION,
		data,
	};
};

export const postStripeACHRePaymentConfimationSuccess = (response) => {
	return {
		type: actionTypes.POST_STRIPE_ACH_RE_PAYMENT_CONFIMATION_SUCCESS,
		response,
	};
};

export const checkCampaignStripeAchRepayment = (campaignId) => {
	return {
		type: actionTypes.CHECK_CAMPAIGN_STRIPE_ACH_RE_PAYMENT,
		campaignId,
	};
};

export const checkCampaignStripeAchRepaymentSuccess = (response) => {
	return {
		type: actionTypes.CHECK_CAMPAIGN_STRIPE_ACH_RE_PAYMENT_SUCCESS,
		response,
	};
};

export const wishLoading = (response) => {
	return {
		type: actionTypes.WISH_LOADING,
		response,
	};
};

export const clearInvestmentSuccessMsg = (response) => {
	return {
		type: actionTypes.WISH_LOADING,
		response,
	};
};

export const becomeEarlyBirdRequest = (earlyBirdCampaign) => {
	return {
		type: actionTypes.BECOME_EARLY_BIRD_REQUEST,
		earlyBirdCampaign,
	};
};

export const becomeEarlyBirdRequestSuccess = (response) => {
	return {
		type: actionTypes.BECOME_EARLY_BIRD_REQUEST_SUCCESS,
		response,
	};
};

export const clearDynamicContract = () => {
	return {
		type: actionTypes.CLEAR_DYNAMIC_CONTRACT,
	};
};

export const generateDynamicContract = (contractData) => {
	return {
		type: actionTypes.GENERATE_DYNAMIC_CONTRACT,
		contractData,
	};
};

export const generateDynamicContractSuccess = (response) => {
	return {
		type: actionTypes.GENERATE_DYNAMIC_CONTRACT_SUCCESS,
		response,
	};
};

export const deleteDynamicContract = (contractData) => {
	return {
		type: actionTypes.DELETE_DYNAMIC_CONTRACT,
		contractData,
	};
};
