import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility/utility";

const initialState = {
  testimonials: [],
  error: null,
};

const testimonialSuccess = (state, action) => {
  return updateObject(state, {
    testimonials: action.response,
  });
};

const testimonialFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_TESTIMONIAL_SUCCESS:
      return testimonialSuccess(state, action);
    case actionTypes.GET_TESTIMONIAL_FAIL:
      return testimonialFail(state, action);
    default:
      return state;
  }
};

export default reducer;
