import * as actionTypes from "./actionTypes";

export const contentPageStart = () => {
	return {
		type: actionTypes.CONTENT_PAGE_START,
	};
};

export const contentPageFail = (error) => {
	return {
		type: actionTypes.CONTENT_PAGE_FAIL,
		error,
	};
};

export const getContentPage = (slug) => {
	return {
		type: actionTypes.GET_CONTENT_PAGE,
		slug,
	};
};

export const getContentPageSuccess = (response) => {
	return {
		type: actionTypes.GET_CONTENT_PAGE_SUCCESS,
		response,
	};
};

export const getCotentPageInvestors = (response) => {
	return {
		type: actionTypes.GET_CONTENT_PAGE_INVESTORS,
		response,
	};
};

export const getContentPageEquity = (slug) => {
	return {
		type: actionTypes.GET_CONTENT_PAGE_EQUITY,
		slug,
	};
};

export const getContentPageEquityResponse = (response) => {
	return {
		type: actionTypes.GET_CONTENT_PAGE_EQUITY_RESPONE,
		response,
	};
};

export const getHomeSection = () => {
	return {
		type: actionTypes.GET_HOME_SECTION,
	};
};

export const getHomeSectionSuccess = (response) => {
	return {
		type: actionTypes.GET_HOME_SECTION_SUCCESS,
		response,
	};
};