import * as actionTypes from "./actionTypes";

export const slidersStart = () => {
  return {
    type: actionTypes.SLIDERS_START,
  };
};

export const slidersFail = (error) => {
  return {
    type: actionTypes.SLIDERS_FAIL,
    error,
  };
};

export const getSliders = (language) => {
  return {
    type: actionTypes.GET_SLIDERS,
    language,
  };
};

export const getSlidersSuccess = (response) => {
  return {
    type: actionTypes.GET_SLIDERS_SUCCESS,
    response,
  };
};
