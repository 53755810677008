import * as actionTypes from "./actionTypes";
export const commentsStart = () => {
	return {
		type: actionTypes.COMMENTS_START,
	};
};

export const commentsFail = (error) => {
	return {
		type: actionTypes.COMMENTS_FAIL,
		error,
	};
};

export const createComment = (commentData) => {
	return {
		type: actionTypes.CREATE_COMMENT,
		commentData,
	};
};

export const createCommentSuccess = (response) => {
	return {
		type: actionTypes.CREATE_COMMENT_SUCCESS,
		response,
	};
};

export const getComments = (id) => {
	return {
		type: actionTypes.GET_COMMENTS,
		id,
	};
};

export const getCommentsSuccess = (response) => {
	return {
		type: actionTypes.GET_COMMENTS_SUCCESS,
		response,
	};
};

export const getAllComments = (id) => {
	return {
		type: actionTypes.GET_ALL_COMMENTS,
		id,
	};
};

export const getAllCommentsSuccess = (response) => {
	return {
		type: actionTypes.GET_ALL_COMMENTS_SUCCESS,
		response,
	};
};

export const getAllSubComments = (parentCommentId) => {
	return {
		type: actionTypes.GET_ALL_SUB_COMMENTS,
		parentCommentId,
	};
};

export const getAllSubCommentsSuccess = (response) => {
	return {
		type: actionTypes.GET_ALL_SUB_COMMENTS_SUCCESS,
		response,
	};
};

export const approveComment = (commentId, id) => {
	return {
		type: actionTypes.APPROVE_COMMENT,
		commentId,
		status: "APPROVE",
		id,
		createdAt: Date.now(),
	};
};

export const approveSubComment = (commentId, parentCommentId) => {
	return {
		type: actionTypes.APPROVE_SUB_COMMENT,
		commentId,
		parentCommentId,
		status: "APPROVE",
		createdAt: Date.now(),
	};
};

export const declineComment = (commentId, id) => {
	return {
		type: actionTypes.DECLINE_COMMENT,
		commentId,
		id,
		status: "DECLINE",
		createdAt: Date.now(),
	};
};

export const declineSubComment = (commentId, parentCommentId) => {
	return {
		type: actionTypes.DECLINE_SUB_COMMENT,
		commentId,
		parentCommentId,
		status: "DECLINE",
		createdAt: Date.now(),
	};
};

export const spamComment = (commentId, id) => {
	return {
		type: actionTypes.SPAM_COMMENT,
		commentId,
		id,
		status: "SPAM",
	};
};

export const spamSubComment = (commentId, parentCommentId) => {
	return {
		type: actionTypes.SPAM_SUB_COMMENT,
		commentId,
		parentCommentId,
		status: "SPAM",
	};
};
export const deleteComment = (commentId, id, parentCommentId) => {
	return {
		type: actionTypes.DELETE_COMMENT,
		commentId,
		id,
		parentCommentId,
	};
};

export const createSubComment = (commentData) => {
	return {
		type: actionTypes.CREATE_SUB_COMMENT,
		commentData,
	};
};

export const getActiveSubComment = (id) => {
	return {
		type: actionTypes.GET_ACTIVE_SUB_COMMENTS,
		id,
	};
};

export const setCommentsFields = (fieldName, value) => {
	return {
		type: actionTypes.SET_COMMENTS_FIELDS,
		fieldName,
		value,
	};
};
