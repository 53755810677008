import React from "react";
import { createRoot } from "react-dom/client";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import "react-phone-number-input/style.css";
import "react-phone-input-2/lib/style.css";
import store, { persistor } from "./helpers/store";
import "./i18n";
import CoreLayout from "./themes/default/layouts/CoreLayout/CoreLayout";

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
	<Provider store={store}>
		<PersistGate loading={null} persistor={persistor}>
			<CoreLayout />
		</PersistGate>
	</Provider>
);
