export const globals = {
	TITLE: `${document.title}`,
	IPADDRESS: "https://api.db-ip.com/v2/free/self",
};

export const RTL_LANGUAGE_LIST = ["ar"];
export const isRTL = RTL_LANGUAGE_LIST.includes(
	localStorage.getItem("i18nextLng")
);

export const EmptyImage =
	"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAAtJREFUGFdjYAACAAAFAAGq1chRAAAAAElFTkSuQmCC";

export const riskAnalysisData = [
	{
		name: "riskAnalysis.lowRisk",
		slug: "low-risk",
		value: 0.5,
	},
	{
		name: "riskAnalysis.lowToModerateRisk",
		slug: "low-to-moderate-risk",
		value: 1.5,
	},
	{
		name: "riskAnalysis.moderateRisk",
		slug: "moderate-risk",
		value: 2.5,
	},
	{
		name: "riskAnalysis.modaratelyHighRisk",
		slug: "moderately-high-risk",
		value: 3.5,
	},
	{
		name: "riskAnalysis.highRisk",
		slug: "high-risk",
		value: 4.5,
	},
	{
		name: "riskAnalysis.veryHighRisk",
		slug: "very-high-risk",
		value: 5.5,
	},
];
