import { createStore, applyMiddleware, compose } from "redux";
import thunkMiddleware from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import rootReducer from "../store/reducers";
import createSagaMiddleware from "redux-saga";
import { routerMiddleware } from "react-router-redux";
import history from "./history";
import {
	watchAuth,
	watchUserRegistration,
	watchUpdateMyPassword,
	watchGetCurrentUser,
	watchUpdateMyProfile,
	watchEmailPreferencers,
	watchInvestorProfile,
	watchResetPassword,
	watchSiteSettings,
	watchSliders,
	watchLanguages,
	watchCampaign,
	watchContentPage,
	watchMeta,
	watchUserProfile,
	watchComments,
	watchCampaignDashboard,
	watchWallet,
	watchTestimonial
} from "../store/sagas";

const composeEnhancers =
	process.env.NODE_ENV === "development"
		? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
		: null || compose;

const sagaMiddleware = createSagaMiddleware();
const persistConfig = {
	key: "root",
	storage
};
const persistedReducer = persistReducer(persistConfig, rootReducer);
const middleware = routerMiddleware(history);

export const store = createStore(
	persistedReducer,
	composeEnhancers(applyMiddleware(thunkMiddleware, sagaMiddleware, middleware))
);
const persistor = persistStore(store);

sagaMiddleware.run(watchAuth);
sagaMiddleware.run(watchUserRegistration);
sagaMiddleware.run(watchUpdateMyPassword);
sagaMiddleware.run(watchGetCurrentUser);
sagaMiddleware.run(watchUpdateMyProfile);
sagaMiddleware.run(watchEmailPreferencers);
sagaMiddleware.run(watchInvestorProfile);
sagaMiddleware.run(watchResetPassword);
sagaMiddleware.run(watchSiteSettings);
sagaMiddleware.run(watchSliders);
sagaMiddleware.run(watchLanguages);
sagaMiddleware.run(watchCampaign);
sagaMiddleware.run(watchContentPage);
sagaMiddleware.run(watchMeta);
sagaMiddleware.run(watchUserProfile);
sagaMiddleware.run(watchComments);
sagaMiddleware.run(watchCampaignDashboard);
sagaMiddleware.run(watchWallet);
sagaMiddleware.run(watchTestimonial);

export default store;
export { persistor };
