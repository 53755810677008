import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility/utility";

const initialState = {
  successMessage: null,
  errors: [],
  loading: false,
};

const updateMyProfileStart = (state, action) => {
  return updateObject(state, {
    errors: null,
    loading: true,
    successMessage: null,
  });
};

const updateMyProfileSuccess = (state, action) => {
  return updateObject(state, {
    errors: [],
    successMessage: true,
    loading: false,
  });
};

const updateMyProfileFail = (state, action) => {
  return updateObject(state, {
    errors: action.errors,
    loading: false,
    successMessage: false,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_PROFILE_START:
      return updateMyProfileStart(state, action);
    case actionTypes.UPDATE_PROFILE_SUCCESS:
      return updateMyProfileSuccess(state, action);
    case actionTypes.UPDATE_PROFILE_FAIL:
      return updateMyProfileFail(state, action);
    default:
      return state;
  }
};

export default reducer;
