import * as actionTypes from "./actionTypes";

export const languagesStart = () => {
	return {
		type: actionTypes.LANGUAGES_START,
	};
};

export const languagesFail = (error) => {
	return {
		type: actionTypes.LANGUAGES_FAIL,
		error,
	};
};

export const getLanguages = () => {
	return {
		type: actionTypes.GET_LANGUAGES,
	};
};

export const getLanguagesSuccess = (response) => {
	return {
		type: actionTypes.GET_LANGUAGES_SUCCESS,
		response,
	};
};

export const getCurrentLanguage = (lang) => {
	return {
		type: actionTypes.GET_CURRENT_LANGUAGE,
		lang,
	};
};

export const getCurrentDefaultLanguage = (lang) => {
	return {
		type: actionTypes.GET_CURRENT_DEFAULT_LANGUAGE,
		lang,
	};
};
