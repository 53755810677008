import * as actionTypes from "../../actions/campaignDashboard/actionTypes";
import { updateObject } from "../../utility/utility";

const initialState = {
	errors: [],
	images: [],
	videos: [],
	teamMembers: [],
	leadInvestors: [],
	campaignInvestorserrors: [],
	campaignInvestors: [],
	campaignInvestorsLoader: false,
	campaignInvestorsSpinner: false,
	galleryImagesLoader: false,
	galleryVideoLoader: false
};

export const galleryStart = (state, action) => {
	return updateObject(state, {
		images: [],
		videos: [],
		errors: [],
		galleryImagesLoader: true,
		galleryVideoLoader: true
	});
};

export const galleryFail = (state, action) => {
	return updateObject(state, {
		errors: action.error,
		galleryImagesLoader: false,
		galleryVideoLoader: false
	});
};

export const getImages = (state, action) => {
	return updateObject(state, {
		images: action.response,
		galleryImagesLoader: false,
	});
};

export const getVideos = (state, action) => {
	return updateObject(state, {
		videos: action.response,
		galleryVideoLoader: false
	});
};

export const getTeamMembers = (state, action) => {
	return updateObject(state, {
		teamMembers: action.response,
	});
};

export const getLeadInvestors = (state, action) => {
	return updateObject(state, {
		leadInvestors: action.response,
	});
};

export const campaignInvestorsComponentFail = (state, action) => {
	return updateObject(state, {
		campaignInvestorserrors: action.error,
	});
};

export const getCampaignInvestors = (state, action) => {
	return updateObject(state, {
		campaignInvestors: action.response,
		campaignInvestorsLoader: false,
		campaignInvestorsSpinner: false
	});
};

export const getCampaignInvestorsStart = (state, action) => {
	return updateObject(state, {
		campaignInvestorsLoader: true,
		campaignInvestorsSpinner: action?.data?.pageLimit > 1 ? true : false
	});
};
export const clearGetCampaignInvestors = (state, action) => {
	return updateObject(state, {
		campaignInvestors: []
	});
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GALLERY_START:
			return galleryStart(state, action);
		case actionTypes.CLEAR_GET_CAMPAIGN_INVESTORS:
			return clearGetCampaignInvestors(state, action);
		case actionTypes.GALLERY_GET_IMAGES_SUCCESS:
			return getImages(state, action);
		case actionTypes.GALLERY_GET_VIDEOS_SUCCESS:
			return getVideos(state, action);
		case actionTypes.GET_CAMPAIGN_INVESTORS_START:
			return getCampaignInvestorsStart(state, action);
		case actionTypes.GET_CAMPAIGN_INVESTORS_SUCCESS:
			return getCampaignInvestors(state, action);
		case actionTypes.GET_TEAM_MEMBERS_SUCCESS:
			return getTeamMembers(state, action);
		case actionTypes.GET_LEAD_INVESTOR_SUCCESS:
			return getLeadInvestors(state, action);
		default:
			return state;
	}
};

export default reducer;
