import axios from "../../../config/AxiosConfig";
import { put } from "redux-saga/effects";
import {
	getRevenueStart,
	getRevenueFail,
	getRevenueSuccess,
} from "../../actions/revenue/revenueActions";
import { REVENUE_API } from "../../actions/apiCollections";

export function* getRevenueSaga() {
	yield put(getRevenueStart());
	try {
		const response = yield axios.get(REVENUE_API);
		yield put(getRevenueSuccess(response.data.data.data));
	} catch (error) {
		yield put(getRevenueFail(error.response.data));
	}
}
