import * as actionTypes from "./actionTypes";

export const updateMyProfileStart = () => {
  return {
    type: actionTypes.UPDATE_PROFILE_START,
  };
};
export const updateMyProfileSuccess = (updateSuccess) => {
  return {
    type: actionTypes.UPDATE_PROFILE_SUCCESS,
    updateSuccess,
  };
};
export const updateMyProfilFail = (error) => {
  return {
    type: actionTypes.UPDATE_PROFILE_FAIL,
    error: false,
  };
};
export const updateMyProfile = (userData) => {
  return {
    type: actionTypes.UPDATE_MY_PROFILE,
    userData: userData,
  };
};
