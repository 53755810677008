import { put } from "redux-saga/effects";
import axios from "../../config/AxiosConfig";
import { RECAPTCHA_API } from "../actions/apiCollections";
import { getRecaptchaSuccess } from "../actions/index";

export function* getRecaptchaSaga() {
  try {
    const response = yield axios.get(RECAPTCHA_API);
    yield put(getRecaptchaSuccess(response.data.data.data));
  } catch (error) {}
}
