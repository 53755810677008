import * as actionTypes from "./actionTypes";

/**
 * -------------------------
 * Reset Password Start (This sends email)
 * -------------------------
 */
export const resetStart = () => {
	return {
		type: actionTypes.RESET_PASSWORD_START
	};
};

export const resetSuccess = successMessage => {
	return {
		type: actionTypes.RESET_PASSWORD_SUCCESS,
		successMessage: successMessage
	};
};

export const resetFail = error => {
	return {
		type: actionTypes.RESET_PASSWORD_FAIL,
		error: error
	};
};

export const resetPassword = email => {
	return {
		type: actionTypes.RESET_PASSWORD,
		email
	};
};

/**
 * -------------------------
 * Forgot Password Start
 * -------------------------
 */

export const forgotPasswordStart = () => {
	return {
		type: actionTypes.FORGOT_PASSWORD_START
	};
};

export const forgotPasswordSuccess = successMessage => {
	return {
		type: actionTypes.FORGOT_PASSWORD_SUCCESS,
		successMessage
	};
};

export const forgotPasswordFail = error => {
	return {
		type: actionTypes.FORGOT_PASSWORD_FAIL,
		error
	};
};

export const forgotPassword = passwordData => {
	return {
		type: actionTypes.FORGOT_PASSWORD,
		passwordData
	};
};

export const clearForgotPassword = () => {
	return {
		type: actionTypes.CLEAR_FORGOT_PASSWORD
	};
};
