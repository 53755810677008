/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import i18n from "i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect, useRef } from "react";
import {
	Container,
	Nav,
	Navbar,
	OverlayTrigger,
	Tooltip,
} from "react-bootstrap";
import { withTranslation } from "react-i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { compose } from "redux";
import classNames from "classnames";

import ChangeLanguage from "./ChangeLanguage/ChangeLanguage";
import { isRTL } from "../../../../constants";
import AppPaths from "../../../../routes/AppPaths";
import { getCurrentUser, getRevenue } from "../../../../store/actions/index";
import * as actions from "../../../../store/actions/index";
import "./Header.scss";
import {
	getLanguageId,
	setLanguageId,
	getUserTypeWiseDisplay,
} from "../../../../helpers/helpers";
import { getBaseImageURL } from "../../../../helpers/url";
import profilePhoto from "../../assets/images/placeholder-avatar.jpg";
import defaultFavicon from "../../assets/images/favicon.png";

function Header(props) {
	const dispatch = useDispatch();
	const userTypeId = localStorage.getItem("userType");
	const globalImagePath = useRef(getBaseImageURL());
	const [expanded, setExpanded] = useState(false);

	const handleToggle = () => {
		setExpanded(!expanded);
	};

	const closeNavbar = () => {
		setExpanded(false);
	};

	useEffect(() => {
		localStorage.getItem("token") && dispatch(getCurrentUser());
		dispatch(getRevenue());
		dispatch(actions.getCurrentLanguage());
		if (
			userTypeId !== null &&
			userTypeId !== undefined &&
			userTypeId !== "undefined"
		) {
			dispatch(actions.userTypes(userTypeId));
		} else {
			dispatch(actions.userTypes(""));
		}
	}, [dispatch, userTypeId]);
	const { siteSettings } = props;
	const { currentLang } = useSelector((state) => state.languages);
	const { userTypes } = useSelector((state) => state.userRegistration);
	const [displayData, setDisplayData] = useState("");
	const [showSubMenu, setShowSubMenu] = useState(false);
	const [logo, setLogo] = useState();

	const { currentUser } = useSelector(
		(state) => state.getCurrentUser,
		shallowEqual
	);

	useEffect(() => {
		if (siteSettings?.siteLogo) {
			setLogo(siteSettings?.siteLogo);
		}
	}, [siteSettings?.siteLogo]);

	useEffect(() => {
		if (userTypes) {
			getUserTypeWiseDisplay(
				userTypes,
				siteSettings?.onlyAdminCreateCampaign,
				currentUser
			);
			setDisplayData(JSON.parse(localStorage.getItem("displayData")));
		}
	}, [userTypes]);

	useEffect(() => {
		if (
			getLanguageId() === null &&
			currentLang !== null &&
			currentLang !== undefined
		) {
			setLanguageId(currentLang);
		}
	}, [currentLang]);

	// Extracting response from API Call

	const { header, footer } = useSelector((state) => state.menu, shallowEqual);
	const { meta } = useSelector((state) => state.meta);
	const { isAuth, t } = props;
	const { firstName, favIcon } = siteSettings;
	const aboutUsLink = footer.filter(
		(_) => _.contentPage?.slug === "about-us1-2"
	);

	// Set the Website title Dynamically
	if (
		!document.location.href.includes("/campaign-detail-page/") &&
		!document.location.href.includes("/campaign-dashboard/") &&
		!document.location.href.includes("/content-page/")
	) {
		const title = meta && meta?.siteName;
		document.title =
			title === undefined ? i18n.t("header.welcomeTitle") : title;
		document
			.querySelector('meta[name="description"]')
			.setAttribute("content", meta?.metaDescription);
	}

	// Set the Menus
	const menus =
		header.length > 0
			? header.map((menu, key) =>
					menu.linkType === "custom" ? (
						<a
							key={key}
							className="nav-link"
							href={menu?.customUrl}
							target={menu?.urlTarget}
						>
							{menu?.title}
						</a>
					) : (
						<Link
							key={key}
							className="nav-link"
							to={`/content-page/${menu?.contentPage?.slug}`}
							target={menu?.urlTarget}
						>
							{menu?.title}
						</Link>
					)
			  )
			: null;

	let favicon = defaultFavicon;

	const fav = document.getElementById("favicon");

	if (!favIcon) {
		fav.setAttribute("href", favicon);
	} else {
		favicon = `${globalImagePath.current}${siteSettings.favIcon}`;
		fav.setAttribute("href", favicon);
	}

	return (
		<>
			<Navbar
				id="mainSiteHeader"
				expand="lg"
				className="ec-navbar h-auto home-scroll-snap"
				data-test="component-header"
				expanded={expanded}
			>
				<Container className="home-custom-container navbar-custom-padding">
					<Link
						id="headerLogoHomePath"
						to={AppPaths.HOME_PATH}
						data-test="component-header-logo"
						onMouseEnter={() => {
							setLogo(siteSettings?.darkLogo);
						}}
						onMouseLeave={() => {
							setLogo(siteSettings?.siteLogo);
						}}
						onClick={closeNavbar}
					>
						<img
							className={classNames(
								"primary-site-logo",
								isRTL ? "mr-4" : "ml-4"
							)}
							src={`${globalImagePath.current}${logo}`}
							alt=""
						/>
					</Link>
					<Navbar.Toggle
						aria-controls="basic-navbar-nav"
						onClick={handleToggle}
					/>
					<Navbar.Collapse id="basic-navbar-nav">
						<Nav className="m-auto">
							{isAuth ? (
								<>
									<Link
										id="headerMenuBrowse"
										className="nav-link"
										to={AppPaths.BROWSE_PATH}
										onClick={closeNavbar}
									>
										{t("menu.browse")}
									</Link>
									<Link
										className="nav-link"
										to={AppPaths.CONTACT_US_PATH}
										onClick={closeNavbar}
									>
										{i18n.t("footerLink.contactUs")}
									</Link>
									{aboutUsLink.map((item, key) => (
										<React.Fragment key={key}>
											{item?.linkType === "custom" ? (
												<a
													href={item?.customUrl}
													target={item?.urlTarget}
													className="nav-link"
													onClick={closeNavbar}
												>
													{item?.title}
												</a>
											) : (
												<Link
													to={"/content-page/" + item?.contentPage?.slug}
													target={item?.urlTarget}
													className="nav-link"
													onClick={closeNavbar}
												>
													{item?.title}
												</Link>
											)}
										</React.Fragment>
									))}
									{displayData?.campaignOwnerDisplay ? (
										<Link
											id="headerCreateCampaign"
											className="nav-link"
											to={AppPaths.PROJECT_GUIDELINES}
											onClick={closeNavbar}
										>
											{t("header.createCampaign")}
										</Link>
									) : (
										""
									)}
								</>
							) : (
								<>
									{menus}

									<Link
										className="nav-link"
										to={AppPaths.HOME_PATH}
										onClick={closeNavbar}
									>
										{i18n.t("global.home")}
									</Link>
									<Link
										id="headerMenuBrowsePublic"
										className="nav-link"
										to={AppPaths.BROWSE_PATH}
									>
										{t("menu.browse")}
									</Link>
									<Link
										className="nav-link"
										to={AppPaths.CONTACT_US_PATH}
										onClick={closeNavbar}
									>
										{i18n.t("footerLink.contactUs")}
									</Link>
									{aboutUsLink.map((item, key) => (
										<React.Fragment key={key}>
											{item?.linkType === "custom" ? (
												<Link
													to={item?.customUrl}
													target={item?.urlTarget}
													className="nav-link"
													onClick={closeNavbar}
												>
													{item?.title}
												</Link>
											) : (
												<Link
													to={"/content-page/" + item?.contentPage?.slug}
													target={item?.urlTarget}
													className="nav-link"
													onClick={closeNavbar}
												>
													{item?.title}
												</Link>
											)}
										</React.Fragment>
									))}
									{/* <Link
										id="headerSignup"
										className="nav-link btn btn-primary"
										to={AppPaths.SIGNUP_PATH}
									>
										{t("menu.signUp")}
									</Link> */}
								</>
							)}
						</Nav>
						{isAuth ? (
							<>
								<OverlayTrigger
									placement="bottom"
									overlay={
										<Tooltip id="tooltipNotifications">
											{t("header.notifications")}
										</Tooltip>
									}
								>
									<Link
										id="headerNotificationDesktop"
										className="nav-link top-notification unread-number"
										to={AppPaths.NOTIFICATION_PATH}
										onClick={closeNavbar}
									>
										<FontAwesomeIcon icon={["fas", "bell"]} className="fos25" />
									</Link>
								</OverlayTrigger>

								<div className="nav-link nav-user-dropdown-wrapper">
									<div
										className="nav-user-info"
										data-test="component-header-placeholder-avatar"
										onClick={() => setShowSubMenu(!showSubMenu)}
									>
										<img
											src={
												currentUser?.photo
													? currentUser?.photo?.includes("users/user") ||
													  currentUser?.photo?.includes("admins/admin")
														? `${globalImagePath.current}${currentUser?.photo}`
														: `${currentUser?.photo}`
													: profilePhoto
											}
											className="nav-user-img"
											alt={firstName}
											onMouseEnter={() => setShowSubMenu(true)}
										/>
										<span className="nav-user-name">
											{currentUser?.firstName} {currentUser?.lastName}
										</span>
										<FontAwesomeIcon
											className="nav-user-arrow"
											icon={[
												"fas",
												showSubMenu ? "chevron-up" : "chevron-down",
											]}
										/>
									</div>
									<div
										className="nav-user-dropdown"
										data-test="component-header-user-dropdown"
									>
										{showSubMenu ? (
											<div className="nav-user-dropdown-inner">
												{displayData?.campaignOwnerDisplay ? (
													<Link
														id="headerDashboard"
														className="nav-user-dropdown-item"
														to={AppPaths.DASHBOARD_PATH}
														onClick={() => {
															setShowSubMenu(false);
															closeNavbar();
														}}
													>
														{t("header.dashboard")}
													</Link>
												) : (
													""
												)}
												<Link
													id="headerProfilePage"
													className="nav-user-dropdown-item"
													to={`/profile/${localStorage.getItem("slug")}`}
													onClick={() => {
														setShowSubMenu(false);
														closeNavbar();
													}}
												>
													{t("header.myProfile")}
												</Link>
												{displayData?.investorDisplay ? (
													<Link
														id="headerInvestments"
														className="nav-user-dropdown-item"
														to={AppPaths.INVESTMENTS_PATH}
														onClick={() => {
															setShowSubMenu(false);
															closeNavbar();
														}}
													>
														{t("header.myInvestments")}
													</Link>
												) : (
													""
												)}
												<Link
													id="headerSavedCampaign"
													className="nav-user-dropdown-item"
													to={AppPaths.SAVED_CAMPAIGN_PATH}
													onClick={() => {
														setShowSubMenu(false);
														closeNavbar();
													}}
												>
													{t("header.savedCampaign")}
												</Link>
												{/* <Link
													id="headerInbox"
													className="nav-user-dropdown-item dropdown-item-messages"
													to={AppPaths.INBOX_PATH}
													onClick={() => {
														setShowSubMenu(false);
														closeNavbar();
													}}
												>
													{t("header.messages")}
												</Link> */}
												<Link
													id="headerNotification"
													className="nav-user-dropdown-item dropdown-item-notifications"
													to={AppPaths.NOTIFICATION_PATH}
													onClick={() => {
														setShowSubMenu(false);
														closeNavbar();
													}}
												>
													{t("header.notifications")}
												</Link>
												<Link
													id="headerInvite"
													className="nav-user-dropdown-item"
													to={AppPaths.INVITE_PATH}
													onClick={() => {
														setShowSubMenu(false);
														closeNavbar();
													}}
												>
													{t("header.inviteFriends")}
												</Link>
												{siteSettings?.walletModule === "yes" ? (
													<Link
														id="headerProfileSetting"
														className="nav-user-dropdown-item"
														to={AppPaths.WALLET_PATH}
														onClick={() => {
															setShowSubMenu(false);
															closeNavbar();
														}}
													>
														{t("wallet.title")}
													</Link>
												) : null}
												<Link
													id="headerProfileSetting"
													className="nav-user-dropdown-item"
													to={AppPaths.PROFILE_SETTINGS_PATH}
													onClick={() => {
														setShowSubMenu(false);
														closeNavbar();
													}}
												>
													{t("header.settings")}
												</Link>
												<Link
													id="headerLogout"
													className="nav-user-dropdown-item"
													data-test="component-header-logout"
													to={AppPaths.LOGOUT_PATH}
													onClick={() => {
														setShowSubMenu(false);
														closeNavbar();
													}}
												>
													{t("header.logout")}
												</Link>
											</div>
										) : null}
									</div>
								</div>
							</>
						) : (
							<Link
								id="headerLogin"
								className="nav-link btn btn-primary sign-in-btn"
								to={AppPaths.LOGIN_PATH}
								data-test="component-header-login"
								onClick={closeNavbar}
							>
								{t("menu.signIn")}
							</Link>
						)}
						<ChangeLanguage />
					</Navbar.Collapse>
				</Container>
			</Navbar>
		</>
	);
}

export default compose(withTranslation())(Header);
