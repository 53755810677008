import * as actionTypes from "./actionTypes";

export const getTestimonials = () => {
  return {
    type: actionTypes.GET_TESTIMONIAL,
  };
};

export const getTestimonialsSuccess = (response) => {
  return {
    type: actionTypes.GET_TESTIMONIAL_SUCCESS,
    response,
  };
};

export const getTestimonialsFail = (error) => {
    return {
      type: actionTypes.GET_TESTIMONIAL_FAIL,
      error,
    };
  };