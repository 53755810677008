import i18n from "i18next";
import { jsPDF } from "jspdf";
import moment from "moment";
import TextSignature from "text-signature";
/**
 * @summary a helper to generate a random transactionKey
 * @returns
 */
export const transactionKey = () => Math.random().toString(36).substr(2, 16);
/**
 * @summary get's the CSS class name according to the status code
 * @param {String} expr Expression (0,1,2,3,4,5,6)
 * @returns {String} `className`
 */
export const getCSSClassName = (expr) => {
	switch (expr) {
		case 0:
			return "text-warning";
		case 1:
			return "text-secondary";
		case 2:
		case 4:
			return "text-success";
		case 3:
		case 5:
		case 6:
			return "text-danger";
		default:
			return "";
	}
};

export const getRebbonCampaignLabel = (expr) => {
	switch (expr) {
		case 0:
			return "ribbon-warning";
		case 1:
			return "ribbon-success";
		case 2:
		case 4:
			return "ribbon-success";
		case 3:
		case 5:
		case 6:
			return "ribbon-primary";
		default:
			return "";
	}
};

/**
 *
 * @param {String} expr
 * @returns {String} `Status`
 */
export const getStatusCodes = (expr) => {
	switch (expr) {
		case 0:
			return i18n.t("campaignStatus.draft");
		case 1:
			return i18n.t("campaignStatus.pending");
		case 2:
			return i18n.t("campaignStatus.active");
		case 3:
			return i18n.t("campaignStatus.declined");
		case 4:
			return i18n.t("campaignStatus.successful");
		case 5:
			return i18n.t("campaignStatus.unsuccessful");
		case 6:
			return i18n.t("campaignStatus.hidden");
		default:
			return "";
	}
};

export const getWalletStatusCodes = (expr) => {
	switch (expr) {
		case 0:
			return i18n.t("wallet.status.pending");
		case 1:
			return i18n.t("wallet.status.inProcess");
		case 2:
			return i18n.t("wallet.status.success");
		case 3:
			return i18n.t("wallet.status.rejected");
		case 4:
			return i18n.t("wallet.status.failed");
		default:
			return "";
	}
};

export const defaultValue = (options, value) =>
	options ? options.find((option) => option.label === value) : "";
export const reactSelectStyle = {
	control: (base, state) => ({
		...base,
		boxShadow: "none",
		minWidth: "242px",
		zIndex: 9999,
		border: "1px solid lightgray", // default border color
		"&:hover": { borderColor: "gray" }, // border style on hover
	}),
	option: (provided, state) => ({
		...provided,
		backgroundColor: state.isSelected ? "#d46514" : "#fff",
	}),
};

export const dayConversion = (expr) => {
	switch (expr) {
		case "Yearly":
			return i18n.t("global.years");
		case "Quarterly":
			return i18n.t("global.quarters");
		case "Monthly":
			return i18n.t("global.month");
		case "Days":
			return i18n.t("global.days");
		default:
			break;
	}
};

export const roiDaysConversion = (expr) => {
	switch (expr) {
		case "Yearly":
			return i18n.t("global.yearly");
		case "Quarterly":
			return i18n.t("global.quarterly");
		case "Monthly":
			return i18n.t("global.monthly");
		case "Days":
			return i18n.t("global.daily");
		default:
			break;
	}
};

/**
 * @summary Captilizes first alphabet of the given string
 * @param {String} text
 * @returns
 */

export const capitalize = (text) => {
	if (text !== undefined) {
		return text.charAt(0).toUpperCase() + text.slice(1);
	}
};

export const setLanguageId = (langId) => {
	localStorage.setItem("selected_language_id", langId);
};

export const getLanguageId = () => {
	return localStorage.getItem("selected_language_id");
};

export const setLanguageCode = (langCode) => {
	localStorage.setItem("i18nextLng", langCode);
};

export const getLanguageCode = () => {
	const languageCode =
		localStorage.getItem("i18nextLng") !== null &&
		(localStorage.getItem("i18nextLng").startsWith("en") ||
			localStorage.getItem("i18nextLng") === "en-US" ||
			localStorage.getItem("i18nextLng") === "dev")
			? "en"
			: localStorage.getItem("i18nextLng");
	return languageCode;
};

export const getUserTypeWiseDisplay = (
	userTypeObj,
	onlyAdminCreateCampaign,
	user
) => {
	let userTypeWiseDisplay = {};
	userTypeWiseDisplay["investorDisplay"] = true;
	userTypeWiseDisplay["campaignOwnerDisplay"] = false;
	localStorage.setItem("displayData", JSON.stringify(userTypeWiseDisplay));
	if (onlyAdminCreateCampaign === "yes" && user?.isAdmin) {
		userTypeWiseDisplay["investorDisplay"] = true;
		userTypeWiseDisplay["campaignOwnerDisplay"] = true;
		localStorage.setItem("displayData", JSON.stringify(userTypeWiseDisplay));
	} else {
		switch (userTypeObj?.slug) {
			case "campaign-owner":
				userTypeWiseDisplay["investorDisplay"] = false;
				userTypeWiseDisplay["campaignOwnerDisplay"] = true;
				localStorage.setItem(
					"displayData",
					JSON.stringify(userTypeWiseDisplay)
				);
				return userTypeWiseDisplay;
			default:
				userTypeWiseDisplay["investorDisplay"] = true;
				userTypeWiseDisplay["campaignOwnerDisplay"] = false;
				localStorage.setItem(
					"displayData",
					JSON.stringify(userTypeWiseDisplay)
				);
				return userTypeWiseDisplay;
		}
	}
	return userTypeWiseDisplay;
};

export const clearTwofactorAuthData = () => {
	document.querySelector("#mainSiteHeader").style.display = "";
	document.querySelector("#mainSiteFooter").style.display = "";
	localStorage.removeItem("twoFactorLoginStatus");
	localStorage.removeItem("twoFactorLoginQRLink");
	localStorage.removeItem("twoFactorLoginUserEmail");
};

export const getLanguageReplaceText = (
	languageText,
	textToReplace,
	defaultText
) => {
	return textToReplace
		? i18n.t(`${languageText}`, {
				val1: textToReplace?.val1,
				val2: textToReplace?.val2,
				val3: textToReplace?.val3,
				val4: textToReplace?.val4,
				val5: textToReplace?.val5,
		  })
		: defaultText;
};

export const getTermLength = (terms) => {
	switch (terms) {
		case "Yearly":
			return 1;
		case "Quarterly":
			return 4;
		case "Monthly":
			return 12;
		case "Days":
			return 365;
		default:
			return 0;
	}
};

export const getTermToQuater = (terms) => {
	switch (terms) {
		case "Yearly":
			return 4;
		case "Quarterly":
			return 1;
		case "Monthly":
			return 0.3335;
		case "Days":
			return 0.01095;
		default:
			return 0;
	}
};

export const getStripedHtml = (html) => {
	let tmp = document.createElement("DIV");
	tmp.innerHTML = html;
	return tmp.textContent || tmp.innerText || "";
};

export const downloadUnsignedContract = (
	htmlData,
	siteSettings,
	currentUser,
	equity
) => {
	const today = moment(Date.now()).format(siteSettings.dateFormat);
	const updatedPdfContent = `${htmlData
		.replace(/{{COMPANY_NAME}}/gi, `${equity?.companyId?.companyName ?? ""}`)
		.replace(/{{DATE_OF_SUBSCRIPTION}}/gi, today ?? "")
		.replace(
			/{{INVESTOR_NAME}}/gi,
			`${currentUser?.firstName} ${currentUser?.lastName ?? ""}`
		)
		.replace(/{{INVESTOR_DATE}}/gi, today)
		.replace(/{{INVESTOR_SSN}}/gi, `${currentUser?.ssn ?? ""}`)
		.replace(/{{INVESTOR_ADDRESS}}/gi, `${currentUser?.userLocation ?? ""}`)
		.replace(/{{TELEPHONE_NUMBER}}/gi, `${currentUser?.phoneNumber ?? ""}`)
		.replace(/{{INVESTOR_EMAIL}}/gi, `${currentUser?.email ?? ""}`)
		.replace(
			/{{INVESTOR_HOME_ADDRESS}}/gi,
			`${currentUser?.userLocation ?? ""}`
		)
		.replace(/{{INVESTOR_TAX_YEAR_END}}/gi, moment().format("yyyy"))}`;

	const doc = new jsPDF();
	doc.html(updatedPdfContent, {
		callback: (doc) => {
			if (doc) {
				doc.save(
					equity ? "Investment Contract.pdf" : "Accreditation Contract.pdf"
				);
			}
		},
		margin: 10,
		autoPaging: "slice",
		width: 180,
		windowWidth: 750,
	});
};

export const englishToArabicCount = (s) =>
	s.replace(/\d/g, (d) => "٠١٢٣٤٥٦٧٨٩"[d]);
export const getPagenumberImg = (pageNumberText) => {
	const optionsParameter = {
		width: 150,
		height: 60,
		paddingX: 0,
		paddingY: 30,
		canvasTargetDom: ".js-canvasTargetDom",
		font: ["35px", "Noto Naskh Arabic"],
		color: "black",
		textString: pageNumberText ?? " ",
		customFont: {
			name: "Noto Naskh Arabic",
			url: "https://fonts.googleapis.com/css?family=Noto%20Naskh%20Arabic",
		},
	};
	let textSignature = new TextSignature(optionsParameter);
	textSignature.generateImage(optionsParameter);
	return textSignature?.imageData;
};

export const getFileNameFromRegister = (name, watch) => {
	const file = watch(name);
	return file?.[0]?.name;
};

export const isTimeOver = (openingDate, openingTime, timezone) => {
	if (isNaN(openingDate) || openingDate <= 0) {
		throw new Error("Invalid opening date");
	}

	if (!/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(openingTime)) {
		throw new Error("Invalid opening time format. Use HH:mm (24-hour)");
	}

	if (!moment.tz.zone(timezone)) {
		throw new Error("Invalid timezone");
	}
	const eventMoment = moment.tz(openingDate, timezone);
	const [hours, minutes] = openingTime.split(":").map(Number);
	eventMoment.hours(hours).minutes(minutes).seconds(0).milliseconds(0);

	const nowMoment = moment.tz(timezone);
	return nowMoment.isAfter(eventMoment);
};
