import { lazy } from "react";
import AppPaths from "./AppPaths";

const Home = lazy(() => import("../themes/default/routes/Home/Home"));
const Login = lazy(() => import("../themes/default/routes/Login/Login"));
const TwoFactorAuth = lazy(() =>
	import("../themes/default/routes/TwoFactorAuth/TwoFactorAuth")
);

const LoginTwoFactorAuth = lazy(() =>
	import("../themes/default/routes/LoginTwoFactorAuth/LoginTwoFactorAuth")
);

const Signup = lazy(() => import("../themes/default/routes/Signup/Signup"));
const BrowseCompanies = lazy(() =>
	import("../themes/default/routes/BrowseCompanies/BrowseCompanies")
);
const BrowseSuccessCampaigns = lazy(() =>
	import(
		"../themes/default/routes/BrowseSuccessCampaigns/BrowseSuccessCampaigns"
	)
);
const RaiseCapital = lazy(() =>
	import("../themes/default/routes/RaiseCapital/RaiseCapital")
);
const Faq = lazy(() => import("../themes/default/routes/Faq/Faq"));
const Dashboard = lazy(() =>
	import("../themes/default/routes/Dashboard/Dashboard")
);
const ForgotPassword = lazy(() =>
	import("../themes/default/routes/ForgotPassword/ForgotPassword")
);
const CampaignDetails = lazy(() =>
	import("../themes/default/routes/CampaignDetails/CampaignDetails")
);
const ChooseType = lazy(() =>
	import("../themes/default/routes/CreateCampaign/ChooseType/ChooseType")
);
const Profile = lazy(() => import("../themes/default/routes/Profile/Profile"));
const ProfileSettings = lazy(() =>
	import("../themes/default/routes/Settings/ProfileSettings/ProfileSettings")
);
const TwoFactorAuthentication = lazy(() =>
	import("../themes/default/routes/Settings/TwoFactorAuthentication/TwoFactorAuthentication")
);
const EmailPreferences = lazy(() =>
	import("../themes/default/routes/Settings/EmailPreferences/EmailPreferences")
);
const ChangePassword = lazy(() =>
	import("../themes/default/routes/Settings/ChangePassword/ChangePassword")
);
const OutsideLinks = lazy(() =>
	import("../themes/default/routes/Settings/OutsideLinks/OutsideLinks")
);
const InvestmentAccount = lazy(() =>
	import(
		"../themes/default/routes/Settings/InvestmentAccount/InvestmentAccount"
	)
);
const PaymentOptions = lazy(() =>
	import("../themes/default/routes/Settings/PaymentOptions/PaymentOptions")
);
const Inbox = lazy(() => import("../themes/default/routes/Inbox/Inbox"));
const Notifications = lazy(() =>
	import("../themes/default/routes/Notifications/Notifications")
);
const Invite = lazy(() => import("../themes/default/routes/Invite/Invite"));
const MyInvestments = lazy(() =>
	import("../themes/default/routes/MyInvestments/MyInvestments")
);
const Investment = lazy(() =>
	import("../themes/default/routes/Contribution/Investment/Investment")
);
const Payment = lazy(() =>
	import("../themes/default/routes/Contribution/Payment/Payment")
);
const InvestmentReceipt = lazy(() =>
	import(
		"../themes/default/routes/Contribution/InvestmentReceipt/InvestmentReceipt"
	)
);
const CompanyInfo = lazy(() =>
	import(
		"../themes/default/routes/CreateCampaign/Equity/CompanyInfo/CompanyInfo"
	)
);
const FundraisingDetail = lazy(() =>
	import(
		"../themes/default/routes/CreateCampaign/Equity/FundraisingDetail/FundraisingDetail"
	)
);
const Story = lazy(() =>
	import("../themes/default/routes/CreateCampaign/Equity/Story/Story")
);
const Faqs = lazy(() =>
	import("../themes/default/routes/CreateCampaign/Equity/Faqs/Faqs")
);
const Extras = lazy(() =>
	import("../themes/default/routes/CreateCampaign/Equity/Extras/Extras")
);
const Funding = lazy(() =>
	import("../themes/default/routes/CreateCampaign/Equity/Funding/Funding")
);
const ThankYou = lazy(() =>
	import("../themes/default/routes/CreateCampaign/Equity/ThankYou/ThankYou")
);
const CampaignDashboard = lazy(() =>
	import("../themes/default/routes/CampaignDashboard/CampaignDashboard")
);
const RepaymentForm = lazy(() =>
	import(
		"../themes/default/routes/CampaignDashboard/components/RepaymentForm/RepaymentForm"
	)
);
const Repayment = lazy(() =>
	import(
		"../themes/default/routes/CampaignDashboard/components/Repayment/Repayment"
	)
);
const RepaymentReceipt = lazy(() =>
	import(
		"../themes/default/routes/CampaignDashboard/components/RepaymentReceipt/RepaymentReceipt"
	)
);
const SavedCampaign = lazy(() =>
	import("../themes/default/routes/SavedCampaign/SavedCampaign")
);
const HelpCenter = lazy(() =>
	import("../themes/default/routes/HelpCenter/HelpCenter")
);
const QuestionList = lazy(() =>
	import("../themes/default/routes/HelpCenter/QuestionList")
);
const SingleQuestion = lazy(() =>
	import("../themes/default/routes/HelpCenter/SingleQuestion")
);
const EmailVerification = lazy(() =>
	import("../themes/default/components/EmailVerification/EmailVerification")
);
const ContentPage = lazy(() =>
	import("../themes/default/routes/ContentPage/ContentPage")
);
const Terms = lazy(() => import("../themes/default/routes/Terms/Terms"));
const AccountActivated = lazy(() =>
	import("../themes/default/routes/AccountActivated/AccountActivated")
);
const ResetPassword = lazy(() =>
	import("../themes/default/routes/ResetPassword/ResetPassword")
);
const ProjectGuidelines = lazy(() =>
	import(
		"../themes/default/routes/CreateCampaign/Equity/ProjectGuidelines/ProjectGuidelines"
	)
);
const InvestmentPaymentReceipt = lazy(() =>
	import(
		"../themes/default/routes/Contribution/InvestmentPaymentReceipt/InvestmentPaymentReceipt"
	)
);

const ContactUs = lazy(() =>
	import("../themes/default/routes/ContactUs/ContactUs")
);

const UserKYC = lazy(() =>
	import("../themes/default/routes/Settings/UserKYC/UserKYC")
);

const StripeConnect = lazy(() =>
	import("../themes/default/routes/StripeConnect/StripeConnect")
);

const StripeACHAccounts = lazy(() =>
	import(
		"../themes/default/routes/Settings/StripeACHAccounts/StripeACHAccounts"
	)
);

const ResendEmail = lazy(() =>
	import("../themes/default/routes/ResendEmail/ResendEmail")
);

const TopupPayment = lazy(() =>
	import("../themes/default/routes/TopupPayment/TopupPayment")
);

const TopupReceiptFail = lazy(() =>
	import("../themes/default/routes/TopupPayment/TopupReceipt/TopupReceiptFail")
);

const TopupReceipt = lazy(() =>
	import("../themes/default/routes/TopupPayment/TopupReceipt/TopupReceipt")
);

const Wallet = lazy(() =>
	import("../themes/default/routes/Wallet/Wallet")
);

const WalletPayment = lazy(() =>
	import("../themes/default/routes/Contribution/WalletPayment/WalletPayment")
);

// eslint-disable-next-line
export default [
	{
		path: AppPaths.RESET_PASSWORD_PATH,
		component: ResetPassword
	},
	{
		path: AppPaths.HOME_PATH,
		component: Home
	},
	{
		path: AppPaths.LOGIN_PATH,
		component: Login
	},
	{
		path: AppPaths.SIGNUP_PATH,
		component: Signup
	},
	{
		path: AppPaths.BROWSE_PATH,
		component: BrowseCompanies
	},
	{
		path: AppPaths.BROWSE_SUCCESS_CAMPAIGN_PATH,
		component: BrowseSuccessCampaigns
	},
	{
		path: AppPaths.RAISE_CAPITAL_PATH,
		component: RaiseCapital
	},
	{
		path: AppPaths.FAQ_PATH,
		component: Faq
	},
	{
		path: AppPaths.DASHBOARD_PATH,
		component: Dashboard
	},
	{
		path: AppPaths.FORGOT_PASSWORD_PATH,
		component: ForgotPassword
	},
	{
		path: AppPaths.CAMPAIGN_DETAIL_PAGE,
		component: CampaignDetails
	},
	{
		path: AppPaths.CHOOSETYPE_PATH,
		component: ChooseType
	},
	{
		path: `${AppPaths.PROFILE_PATH}/:slug`,
		component: Profile
	},
	{
		path: AppPaths.PROFILE_SETTINGS_PATH,
		component: ProfileSettings
	},
	{
		path: AppPaths.TWO_FACTOR_SETTINGS_PATH,
		component: TwoFactorAuthentication
	},
	{
		path: AppPaths.EMAIL_PREFERENCES_PATH,
		component: EmailPreferences
	},
	{
		path: AppPaths.CHANGE_PASSWORD_PATH,
		component: ChangePassword
	},
	{
		path: AppPaths.OUTSIDE_LINKS_PATH,
		component: OutsideLinks
	},
	{
		path: AppPaths.INBOX_PATH,
		component: Inbox
	},
	{
		path: AppPaths.NOTIFICATION_PATH,
		component: Notifications
	},
	{
		path: AppPaths.INVITE_PATH,
		component: Invite
	},
	{
		path: AppPaths.INVESTMENTS_PATH,
		component: MyInvestments
	},
	{
		path: AppPaths.INVESTMENTACCOUNT_PATH,
		component: InvestmentAccount
	},
	{
		path: AppPaths.PAYMENTOPTIONS_PATH,
		component: PaymentOptions
	},
	{
		path: AppPaths.INVEST_PATH,
		component: Investment
	},
	{
		path: AppPaths.PAYMENT_PATH,
		component: Payment
	},
	{
		path: AppPaths.RECEIPT_PATH,
		component: InvestmentReceipt
	},
	{
		path: AppPaths.COMPANYINFO_PATH,
		component: CompanyInfo
	},
	{
		path: AppPaths.FUNDRAISING_DETAIL_PATH,
		component: FundraisingDetail
	},
	{
		path: AppPaths.STORY_PATH,
		component: Story
	},
	{
		path: AppPaths.FAQS_PATH,
		component: Faqs
	},
	{
		path: AppPaths.EXTRAS_PATH,
		component: Extras
	},
	{
		path: AppPaths.FUNDING_PATH,
		component: Funding
	},
	{
		path: AppPaths.THANK_YOU_PATH,
		component: ThankYou
	},
	{
		path: AppPaths.CAMPAIGN_DASHBOARD_PATH,
		component: CampaignDashboard
	},
	{
		path: AppPaths.REPAYMENTTAB_PATH,
		component: RepaymentForm
	},
	{
		path: AppPaths.REPAYMENT_PATH,
		component: Repayment
	},
	{
		path: AppPaths.SAVED_CAMPAIGN_PATH,
		component: SavedCampaign
	},
	{
		path: AppPaths.REPAYMENT_RECEIPT_PATH,
		component: RepaymentReceipt
	},
	{
		path: AppPaths.HELP_CENTER_PATH,
		component: HelpCenter
	},
	{
		path: AppPaths.QUESTION_LIST_PATH,
		component: QuestionList
	},
	{
		path: AppPaths.SINGLE_QUESTION_PATH,
		component: SingleQuestion
	},
	{
		path: AppPaths.EMAIL_VERIFICATION,
		component: EmailVerification
	},
	{
		path: AppPaths.CONTENT_PAGE,
		component: ContentPage
	},
	{
		path: AppPaths.TERMS_OF_USE,
		component: Terms
	},
	{
		path: AppPaths.ACTIVATE_ACCOUNT,
		component: AccountActivated
	},
	{
		path: AppPaths.PROJECT_GUIDELINES,
		component: ProjectGuidelines
	},
	{
		path: AppPaths.SUCCESS_PAYMENT_RECEIPT_PATH,
		component: InvestmentPaymentReceipt
	},
	{
		path: AppPaths.CONTACT_US_PATH,
		component: ContactUs
	},
	{
		path: AppPaths.USER_KYC_PATH,
		component: UserKYC
	},
	{
		path: AppPaths.TWO_FACTOR_AUTHENTICATION_PATH,
		component: TwoFactorAuth
	},
	{
		path: AppPaths.STRIPE_ACCOUNT_CONNECTED_PATH,
		component: StripeConnect
	},
	{
		path: AppPaths.STRIPE_ACH_ACCOUNTS_LIST_PATH,
		component: StripeACHAccounts
	},
	{
		path: AppPaths.RESEND_VERIFICATION_EMAIL_PATH,
		component: ResendEmail
	},
	{
		path: AppPaths.LOGIN_TWO_FACTOR_AUTHENTICATION_PATH,
		component: LoginTwoFactorAuth
	},
	{
		path: AppPaths.TOP_UP_PAYMENT_PATH,
		component: TopupPayment
	},
	{
		path: AppPaths.TOP_UP_RECEIPT_FAIL_PATH,
		component: TopupReceiptFail
	},
	{
		path: AppPaths.TOP_UP_RECEIPT_PATH,
		component: TopupReceipt
	},
	{
		path: AppPaths.WALLET_PATH,
		component: Wallet
	},
	{
		path: AppPaths.WALLET_PAYMENT_PATH,
		component: WalletPayment
	},
	{
		path: "*",
		component: Home
	}
];
