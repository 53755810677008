import { put } from "redux-saga/effects";
import axios from "../../config/AxiosConfig";
import { TESTIMONIALS_API } from "../actions/apiCollections";
import { getTestimonialsSuccess, getTestimonialsFail } from "../actions/index";
import { getLanguageId } from "../../helpers/helpers";

export function* getTestimonials() {
	try {
		const response = yield axios.get(TESTIMONIALS_API, {
			params: {
				...(getLanguageId() !== null && {
					language: getLanguageId()
				})
			}
		});
		yield put(getTestimonialsSuccess(response.data.data.data));
	} catch (error) {
		yield put(getTestimonialsFail(error.response));
	}
}
