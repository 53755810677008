import React from "react";
import { useTranslation } from "react-i18next";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import i18n from "i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import footerCompanyLogo from "../../../assets/images/companylogo-footer.png";
import "../Footer.scss";

function SocialLinks({ heading }) {
	const { t } = useTranslation();
	const { socialMedia } = useSelector((state) => state.menu);
	return (
		<>
			<Card className="border-0 social-link-main-wraper">
				<Card.Body className="p-0 company-logo-wraper">
					<Card.Img src={footerCompanyLogo} alt="company logo" />
				</Card.Body>
				<Card.Text className="footer-company-description">
					{i18n.t("footerLink.abountusBrif")}{" "}
					<Link to={"/content-page/about-us1-2"}>
						{i18n.t("footerLink.readMore")}
					</Link>
				</Card.Text>
				<Card.Body className="social-link-wrapper">
					{socialMedia?.map((item, key) => (
						<React.Fragment key={key}>
							<Card.Link
								href={item.customUrl}
								className="social-link"
								title={t(item.title)}
								key={key}
								target={item.urlTarget}
							>
								<FontAwesomeIcon
									icon={[
										item.socialMediaIcon.split(" ")[0],
										item.socialMediaIcon.split(" ")[1],
									]}
									className="social-icon"
								/>
							</Card.Link>
						</React.Fragment>
					))}
				</Card.Body>
			</Card>
		</>
	);
}

SocialLinks.propTypes = {
	heading: PropTypes.string.isRequired,
	linkItem: PropTypes.array,
};
export default SocialLinks;
