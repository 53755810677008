import * as actionTypes from "../../actions/menu/actionTypes";
import { updateObject } from "../../utility/utility";

const initialState = {
  error: null,
  header: [],
  footer: [],
  socialMedia: [],
  footerFaqs: []
};

const headerSuccess = (state, action) => {
  return updateObject(state, {
    header: action.response,
  });
};

const footerSuccess = (state, action) => {
  return updateObject(state, {
    footer: action.response,
  });
};

const socialMediaSuccess = (state, action) => {
  return updateObject(state, {
    socialMedia: action.response,
  });
};

const footerFaqsSuccess = (state, action) => {
  return updateObject(state, {
    footerFaqs: action.response,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.STORE_HEADER:
      return headerSuccess(state, action);
    case actionTypes.STORE_FOOTER:
      return footerSuccess(state, action);
    case actionTypes.STORE_SOCIAL_MEDIA:
      return socialMediaSuccess(state, action);
    case actionTypes.GET_FOOTER_FAQS_SUCCESS:
    return footerFaqsSuccess(state, action);
    default:
      return state;
  }
};
export default reducer;
