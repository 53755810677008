import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Row, Col, Card } from "react-bootstrap";
import PropTypes from "prop-types";

import "../Footer.scss";

const FooterLinks = ({ heading, linkItem, columnNum }) => {
	const { t } = useTranslation();

	return (
		<>
			{heading && (
				<Card.Title className="footer-heading">{t(heading)}</Card.Title>
			)}
			<Row className="footer-link-wrapper">
				{linkItem.map((item, key) => (
					<Col sm={columnNum} className="footer-link-item" key={key}>
						{item?.linkType === "custom" ? (
							<Card.Link
								href={item?.customUrl}
								target={item?.urlTarget}
								className="footer-link"
							>
								{item?.title}
							</Card.Link>
						) : (
							<Link
								to={"/content-page/" + item?.contentPage?.slug}
								target={item?.urlTarget}
								className="footer-link"
							>
								{item?.title}
							</Link>
						)}
					</Col>
				))}
			</Row>
		</>
	);
};

FooterLinks.propTypes = {
	heading: PropTypes.string,
	linkItem: PropTypes.array,
	columnNum: PropTypes.number
};
export default FooterLinks;
