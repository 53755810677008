export const CONTENT_PAGE_START = "CONTENT_PAGE_START";
export const CONTENT_PAGE_FAIL = "CONTENT_PAGE_FAIL";
export const GET_CONTENT_PAGE = "GET_CONTENT_PAGE";
export const GET_CONTENT_PAGE_SUCCESS = "GET_CONTENT_PAGE_SUCCESS";
export const GET_CONTENT_PAGE_INVESTORS = "GET_CONTENT_PAGE_INVESTORS";
export const GET_CONTENT_PAGE_EQUITY = "GET_CONTENT_PAGE_EQUITY";
export const GET_CONTENT_PAGE_EQUITY_RESPONE =
"GET_CONTENT_PAGE_EQUITY_RESPONE";
export const GET_HOME_SECTION = "GET_HOME_SECTION";
export const GET_HOME_SECTION_SUCCESS = "GET_HOME_SECTION_SUCCESS";
