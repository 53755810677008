import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility/utility";

const initialState = {
	siteSettings: [],
	error: null,
	loading: false,
	campaignSettings: [],
	currencies: [],
	campaignACHSetting: null,
	googleSetting:{},
	linkedinSetting:{},
	facebookSetting:{},
};

const siteStart = (state, action) => {
	return updateObject(state, { error: null, loading: true });
};

const siteSuccess = (state, action) => {
	return updateObject(state, {
		siteSettings: action.response
	});
};

const campaignSettingsSuccess = (state, action) => {
	return updateObject(state, {
		campaignSettings: action.response
	});
};

const getCurrencies = (state, action) => {
	return updateObject(state, {
		currencies: action.response
	});
};

const siteFail = (state, action) => {
	return updateObject(state, {
		error: action.error
	});
};

const getACHCampaignSettingSuccess = (state, action) => {
	return updateObject(state, {
		campaignACHSetting: action.response[0]
	});
};

const getGoogleSettingSuccess = (state, action) => {
	return updateObject(state, {
		googleSetting: action.response
	});
};

const getFacebookSettingSuccess = (state, action) => {
	return updateObject(state, {
		facebookSetting: action.response
	});
};

const getLinkedinSettingSuccess = (state, action) => {
	return updateObject(state, {
		linkedinSetting: action.response
	});
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.SITE_SETTINGS_START:
			return siteStart(state, action);
		case actionTypes.GET_SITE_SETTINGS_SUCCESS:
			return siteSuccess(state, action);
		case actionTypes.GET_CAMPAIGN_SETTINGS_SUCCESS:
			return campaignSettingsSuccess(state, action);
		case actionTypes.GET_CURRENCY_SUCCESS:
			return getCurrencies(state, action);
		case actionTypes.SITE_SETTINGS_FAIL:
			return siteFail(state, action);
		case actionTypes.GET_ACH_CAMPAIGN_SETTING_SUCCESS:
			return getACHCampaignSettingSuccess(state, action);
		case actionTypes.GET_SOCIAL_GOOGLE_SETTING_SUCCESS:
			return getGoogleSettingSuccess(state, action);
		case actionTypes.GET_SOCIAL_FACEBOOK_SETTING_SUCCESS:
			return getFacebookSettingSuccess(state, action);
		case actionTypes.GET_SOCIAL_LINKEDIN_SETTING_SUCCESS:
			return getLinkedinSettingSuccess(state, action);
		default:
			return state;
	}
};

export default reducer;
