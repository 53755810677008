export const USER_PROFILE_START = "[Profile Component] User Profile Start";
export const USER_PROFILE_FAIL = "[Profile Component] User Profile Fail";
export const GET_PROFILE = "[Profile Component] Get Profile";
export const GET_PROFILE_SUCCESS = "[Profile Component] Get Profile Success";
export const GET_FOLLOWERS = "[Profile Component] Get Followers";
export const GET_FOLLOWERS_SUCCESS =
	"[Profile Component] Get Followers Success";
export const GET_FOLLOWING = "[Profile Component] Get Following";
export const GET_FOLLOWING_SUCCESS =
	"[Profile Component] Get Following Success";
export const FOLLOW_USER = "[Profile Component] FOLLOW USER";
export const UNFOLLOW_USER = "[Profile Component] UNFOLLOW USER";
export const VERIFY_EMAIL = "[EMAIL COMPONENT] Verify Email";
export const VERIFY_EMAIL_SUCCESS = "[EMAIL COMPONENT] Verify Email Success";
export const GET_USER_ACTIVITIES =
	"[NOTIFICATION MODULE] Get User Notifications";
export const GET_USER_ACTIVITIES_SUCCESS =
	"[NOTIFICATION MODULE] Get User Notifications Success";
export const GET_CURRENT_USER = "[GLOBAL] Get Current User";
export const GET_CURRENT_USER_SUCCESS = "[GLOBAL] Get Current User Success";
export const GET_STATISTICS = "[DASHBOARD] Get Statistics";
export const GET_STATISTICS_SUCCESS = "[DASHBOARD] Get Statistics Success";

export const USER_INVITE_START = "[Invite Component] User Invite Start";
export const USER_INVITE = "[Invite Component] User Invite";
export const USER_INVITE_FAIL = "[Invite Component] User Invite Fail";
export const USER_INVITE_SUCCESS = "[Invite Component] User Invite Success";

export const USER_CONTACT_US_START =
	"[Contact Us Component] USER_CONTACT_US_START";
export const USER_CONTACT_US = "[Contact Us Component] USER_CONTACT_US";
export const USER_CONTACT_US_FAIL =
	"[Contact Us Component] USER_CONTACT_US_FAIL";
export const USER_CONTACT_US_SUCCESS =
	"[Contact Us Component] USER_CONTACT_US_SUCCESS";
