export const ACTIVATE_ACCOUNT_START =
	"[Email Activation] Activate Account Start";
export const ACTIVATE_ACCOUNT = "[Email Activation] Activate Account";
export const ACTIVATE_ACCOUNT_SUCCESS =
	"[Email Activation] Activate Account Success";
export const ACTIVATE_ACCOUNT_FAIL = "[Email Activation] Activate Account Fail";
export const IS_SPINNING = "[GLOBAL] IS SPINNING";
export const SHOW_ALERT = "[GLOBAL] SHOW ALERT";

export const AUTH_TWO_FACTOR_ACTIVATE_ACCOUNT =
	"AUTH_TWO_FACTOR_ACTIVATE_ACCOUNT";
export const AUTH_TWO_FACTOR_LOGIN = "AUTH_TWO_FACTOR_LOGIN";
export const AUTH_TWO_FACTOR_ACTIVATE_ACCOUNT_SUCCESS =
	"AUTH_TWO_FACTOR_ACTIVATE_ACCOUNT_SUCCESS";
export const CLEAR_ERROR_AUTH_TWO_FACTOR_ACTIVATE_ACCOUNT =
	"CLEAR_ERROR_AUTH_TWO_FACTOR_ACTIVATE_ACCOUNT";
export const TWO_FACTOR_LOGIN_FAIL = "TWO_FACTOR_LOGIN_FAIL";
export const CLEAR_TWO_FACTOR_LOGIN_FAIL = "CLEAR_TWO_FACTOR_LOGIN_FAIL";
export const GET_TWO_FACTOR_QR_CODE = "GET_TWO_FACTOR_QR_CODE";
export const GET_TWO_FACTOR_QR_CODE_SUCCESS = "GET_TWO_FACTOR_QR_CODE_SUCCESS";

