/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import i18n from "i18next";
import { Card, Col, Form, Image, Row } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import classNames from "classnames";

import * as actions from "../../../../../store/actions/index";
import * as campaignActions from "../../../../../store/actions/createCampaign/createCampaignAction";
import { getMeta } from "../../../../../store/actions/meta/metaActions";
import { setCookie } from "../../../../../helpers/cookieHelper";
import globe from "../../../assets/images/globe.svg";
import "./ChangeLanguage.scss";
import { isRTL } from "../../../../../constants";

function ChangeLanguage(props) {
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(actions.getLanguages());
		dispatch(actions.getMenuItem("header", "footer", "socialMedia"));
	}, [dispatch]);

	const { i18n } = props;
	const { languages, currentLang, defaultLang } = useSelector(
		(state) => state.languages
	);
	/**
	 * languages from API filtered
	 */
	let lang = languages.map(({ name: label, folder: value, ...rest }) => ({
		label,
		value,
		...rest,
	}));

	let defaultLanguage = defaultLang;

	lang
		.filter((item) => item.value === localStorage.getItem("i18nextLng"))
		.map((item) => {
			return (defaultLanguage = item);
		});

	useEffect(() => {
		if (localStorage.getItem("selected_language_id") === null) {
			const fallbackLanguage = "ar";
			i18n.changeLanguage(fallbackLanguage);
			localStorage.setItem("i18nextLng", fallbackLanguage);
			setCookie("i18next", fallbackLanguage);
		}
	}, [currentLang]);

	const changeLanguage = (language) => {
		i18n.changeLanguage(language.value);
		dispatch(actions.getSliders(language.id));
		dispatch(
			actions.getMenuItem("header", "footer", "socialMedia", language.id)
		);
		dispatch(campaignActions.getCategories(language.id));
		dispatch(campaignActions.getIndustries(language.id));
		dispatch(campaignActions.getTeamMemberType(language.id));
		dispatch(campaignActions.getCampaignStage(language.id));
		dispatch(campaignActions.getInvestingRound(language.id));
		dispatch(campaignActions.getInvestor(language.id));

		localStorage.setItem("i18nextLng", language.value);
		setCookie("i18next", language.value);
		if (localStorage.getItem("selected_language_id") !== language.id) {
			localStorage.setItem("selected_language_id", language.id);
			dispatch(getMeta(localStorage.getItem("selected_language_id")));
			window.location.reload();
		}
	};

	return (
		<Row className={classNames(isRTL ? "mr-2" : "ml-2", "align-items-center")}>
			<Col xs="auto" className="p-0 mx-1">
				<Card.Body className="globe-wrapper">
					<Image className="globe-image" src={globe} />
				</Card.Body>
			</Col>
			<Col xs="auto" className="p-0">
				<Form className="language-change">
					<Form.Label className="mb-0 language-font-label">
						{i18n.t("global.language")}
					</Form.Label>
					<Select
						defaultValue={defaultLanguage}
						onChange={changeLanguage}
						options={lang}
						inputId={lang.id}
						classNamePrefix="react-select"
						className="react-select-container"
						menuPlacement="auto"
						isSearchable={false}
					/>
				</Form>
			</Col>
		</Row>
	);
}

export default withTranslation()(ChangeLanguage);
