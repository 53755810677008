/* eslint-disable react-hooks/exhaustive-deps */
import React, { Suspense, useLayoutEffect, useState, useEffect } from "react";
import axios from "axios";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import "bootstrap/dist/css/bootstrap.min.css";
import moment from "moment";
import "moment/locale/ar";
import "moment/locale/fr";
import "moment-timezone";
import { connect } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import AuthenticateRoutes from "../../../../routes/AuthenticateRoutes";
import routes from "../../../../routes/routes";
import * as actions from "../../../../store/actions/index";
import { getCampaignSettings } from "../../../../store/actions/siteSettings";
import "../../assets/sass/styles.scss";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import Loading from "../../components/Loading/Loading";
import { PrivateRoute, ProvideAuth } from "../../routes/AuthContext/auth";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
import Maintenance from "../../components/maintenance/Maintenance";
import { getLanguageId } from "../../../../helpers/helpers";
import "react-toggle/style.css";

library.add(fab, fas, far);

function CoreLayout(props) {
	const [ip, setIP] = useState("");

	const { languages } = useSelector(state => state.languages);
	const selectedLanguage = languages?.filter(lng => lng.id === getLanguageId());
	const selectedLanguageDirection =
		selectedLanguage?.[0]?.folder === "ar" ? "rtl" : "ltr";
	document
		.getElementsByTagName("html")[0]
		.setAttribute("dir", selectedLanguageDirection);
	document
		.getElementsByTagName("html")[0]
		.setAttribute("lang", selectedLanguage?.[0]?.folder);

	useLayoutEffect(() => {
		props.onTryAutoSignup();
		props.onRecaptcha();
		props.onGetSiteSettings();
		props.onGetCampaignSettings();
		getData();
	}, []);

	useEffect(() => {
		const day = true;
		const root = document.documentElement;
		root.style.setProperty(
			"--background-color-front",
			day ? props?.siteSettings?.sitePrimaryColor : "#6048fe"
		);

		root.style.setProperty(
			"--background-secondary-color",
			day ? props?.siteSettings?.siteSecondaryColor : "#000"
		);

		root.style.setProperty("--direction", selectedLanguageDirection);
	}, [
		props?.siteSettings?.sitePrimaryColor,
		props?.siteSettings?.siteSecondaryColor
	]);

	const getData = async () => {
		const res = await axios.get("https://geolocation-db.com/json/");
		setIP(res.data.IPv4);
	};

	moment.tz.setDefault(props.siteSettings.timezone);
	moment.locale(selectedLanguage?.[0]?.folder);
	let mainRoutes = (
		<React.Fragment>
			<ProvideAuth>
				<Switch>
					{!props.isAuthenticated ? (
						routes.map((route, i) => (
							<Route
								path={route.path}
								key={i}
								exact
								render={props => (
									<route.component
										{...props}
										routes={route.routes}
										env={props.env}
									/>
								)}
							/>
						))
					) : (
						<PrivateRoute>
							{AuthenticateRoutes.map((route, i) => (
								<Route
									path={route.path}
									key={i}
									exact
									render={props => (
										<route.component
											{...props}
											routes={route.routes}
											env={props.env}
										/>
									)}
								/>
							))}
						</PrivateRoute>
					)}
				</Switch>
			</ProvideAuth>
		</React.Fragment>
	);

	return (
		<Router basename={`/`}>
			{props.siteSettings.maintainanceMode === "yes" &&
			props.siteSettings.exceptIP !== ip ? (
				<h1>{props.siteSettings === [] ? <Loading /> : <Maintenance />}</h1>
			) : (
				<div>
					<Header
						isAuth={props.isAuthenticated}
						currentUser={props.currentUser}
						siteSettings={props.siteSettings}
					/>
					<ScrollToTop />
					<Suspense fallback={<Loading />}>{mainRoutes}</Suspense>
					<Footer />
				</div>
			)}
		</Router>
	);
}

const mapStateToProps = state => {
	return {
		isAuthenticated: state.auth.token !== null,
		reCaptchaKey: state.recaptcha.recaptcha,
		currentUser: state.getCurrentUser.currentUser,
		siteSettings: state.siteSettings.siteSettings
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onTryAutoSignup: () => dispatch(actions.authCheckState()),
		onRecaptcha: () => dispatch(actions.getRecaptcha()),
		onGetCurrentUser: () => dispatch(actions.getCurrentUser()),
		onGetSiteSettings: () => dispatch(actions.getSiteSettings()),
		onGetCampaignSettings: () => dispatch(getCampaignSettings())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(CoreLayout);
