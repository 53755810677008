import * as actionTypes from "./actionTypes";

export const getRecaptcha = () => {
  return {
    type: actionTypes.GET_RECAPTCHA,
  };
};

export const getRecaptchaSuccess = (response) => {
  return {
    type: actionTypes.GET_RECAPTCHA_SUCCESS,
    response,
  };
};
