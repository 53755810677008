export const GET_MY_INVESTMENTS_START =
	"[My Investments] Get My Investments Start";
export const GET_MY_INVESTMENTS = "[My Investments] Get My Investments";
export const GET_MY_INVESTMENTS_SUCCESS =
	"[My Investments] Get My Investments Success";
export const GET_INVESTMENTS_CHART_DATA = "GET_INVESTMENTS_CHART_DATA";
export const GET_INVESTMENTS_CHART_DATA_SUCCESS =
	"GET_INVESTMENTS_CHART_DATA_SUCCESS";
export const GET_INVESTMENTS_COLUMN_CHART_DATA =
	"GET_INVESTMENTS_COLUMN_CHART_DATA";
export const GET_INVESTMENTS_COLUMN_CHART_DATA_SUCCESS =
	"GET_INVESTMENTS_COLUMN_CHART_DATA_SUCCESS";
export const GET_MY_INVESTMENTS_GRAPH_SUCCESS =
	"[My Investments] Get My Investments Graph Success";
export const GET_MY_INVESTMENTS_FAIL =
	"[My Investments] Get My Investments Fail";

export const GET_USER_INVESTMENT_UNIQUE_CURRENCIES =
	"GET_USER_INVESTMENT_UNIQUE_CURRENCIES";
export const GET_USER_INVESTMENT_UNIQUE_CURRENCIES_SUCCESS =
	"GET_USER_INVESTMENT_UNIQUE_CURRENCIES_SUCCESS";
export const GET_USER_INVESTMENT_FULL_DETAIL =
	"GET_USER_INVESTMENT_FULL_DETAIL";
export const GET_USER_INVESTMENT_FULL_DETAIL_SUCCESS =
	"GET_USER_INVESTMENT_FULL_DETAIL_SUCCESS";
export const GET_USER_INVESTMENT_FULL_DETAIL_FAIL =
	"GET_USER_INVESTMENT_FULL_DETAIL_FAIL";
export const CLEAR_USER_INVESTMENT_FULL_DETAIL =
	"CLEAR_USER_INVESTMENT_FULL_DETAIL";
export const GET_USER_INVESTMENT_DOCUMENT_DETAIL =
	"GET_USER_INVESTMENT_DOCUMENT_DETAIL";
export const GET_USER_INVESTMENT_DOCUMENT_DETAIL_SUCCESS =
	"GET_USER_INVESTMENT_DOCUMENT_DETAIL_SUCCESS";
export const GET_USER_INVESTMENT_DOCUMENT_DETAIL_FAIL =
	"GET_USER_INVESTMENT_DOCUMENT_DETAIL_FAIL";
export const CLEAR_USER_INVESTMENT_DOCUMENT_DETAIL =
	"CLEAR_USER_INVESTMENT_DOCUMENT_DETAIL";
export const SET_INVESMENT_SELECTED_CURRENCY =
	"SET_INVESMENT_SELECTED_CURRENCY";

export const GET_RESIGN_CONTRACT_LIST = "GET_RESIGN_CONTRACT_LIST";
export const GET_RESIGN_CONTRACT_LIST_SUCCESS =
	"GET_RESIGN_CONTRACT_LIST_SUCCESS";
