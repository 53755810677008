export const aboutusLinks = {
	heading: "footerTitle.aboutUs",
	links: [
		{
			title: "footerLink.aboutUs",
			href: "",
		},
		{
			title: "footerLink.helpCenter",
			href: "/help-center",
		},
		{
			title: "footerLink.faq",
			href: "",
		},
		{
			title: "footerLink.contactUs",
			href: "",
		},
	],
};

export const quickLinks = {
	heading: "footerTitle.quickLinks",
	links: [
		{
			title: "footerLink.riskWarning",
			href: "",
		},
		{
			title: "footerLink.howToList",
			href: "",
		},
		{
			title: "footerLink.services",
			href: "",
		},
		{
			title: "footerLink.whatEquityCrowdfunding",
			href: "",
		},
		{
			title: "footerLink.fees",
			href: "",
		},
		{
			title: "footerLink.privacyPolicy",
			href: "",
		},
		{
			title: "footerLink.termsOfUse",
			href: "",
		},
	],
};

export const socialLinks = {
	heading: "footerTitle.letsSocialize",
	links: [
		{
			title: "socialLink.facebook",
			icon: "facebook-f",
			iconGroup: "fab",
			href: "",
		},
		{
			title: "socialLink.twitter",
			icon: "twitter",
			iconGroup: "fab",
			href: "",
		},
		{
			title: "socialLink.linkedin",
			icon: "linkedin-in",
			iconGroup: "fab",
			href: "",
		},
		{
			title: "socialLink.youtube",
			icon: "youtube",
			iconGroup: "fab",
			href: "",
		},
	],
};
